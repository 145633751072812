<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR08.jpg" alt='Progress Bar 10 of 10'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide - Completed!</h3>
            <hr class="custom-line">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p>Thank you for completing this questionnaire!</p>
            <p>&nbsp;</p>
            <p>Clicking the button below will generate your Personalized Guide and automatically download it.</p>
        </div>
    </div>

    <div class="row">
        <div *ngIf="activeButton" class="col-12">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitCreateGuide()">Create my Personalized Guide!</button>
        </div>
        <div *ngIf="!activeButton" class="col-12">
            <button class="btn btn-custom-grey mb-sm-0 mb-2" (click)="onSubmitInactiveButton()">Please wait</button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p *ngIf="waitForGuide">Please wait while the guide is being created and downloaded... (may take up to 15 seconds)</p>

            <p *ngIf="guideCompleted && !pdfNotFoundError">Please wait while the guide is being created and downloaded...
                <b> Completed!</b><small> (Please check your "Downloads" folder for a file named "Personal Guide_date_time")</small></p>
        </div>
    </div>

    <div *ngIf="pdfNotFoundError" class="invalid-feedback d-block">
        There was an error in generating your Personalized Guide. Please refresh the page and try again.  If this continues, please contact 
        <a href="mailto:taxassistance@nrvtap.com">taxassistance@nrvtap.com</a> for assistance.
    </div>

    <div *ngIf="pdfNotFoundError" class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
        <div class="col-12">
            <p>Click the link below to go to back to the NRVTAP site.</p>
            <button class="btn btn-custom mb-sm-0 mb-2" onclick="window.location.href='https://www.nrvtap.com/secure/prepreturnnr';">NRVTAP site</button>
        </div>
    </div>

    <div *ngIf="!(waitForGuide || guideCompleted)" class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>

    <div *ngIf="guideCompleted" class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
        <div class="col-12">
            <p>Click the link below to go to the NRVTAP site to prepare your return.  The link to the TaxSlayer software is on this page.</p>
            <button class="btn btn-custom mb-sm-0 mb-2" onclick="window.location.href='https://www.nrvtap.com/secure/prepreturnnr';">NRVTAP Step 2 - Prepare My Return</button>
        </div>
    </div>

</div>