import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ctbdata, countryindexes, stateset, binolean } from './TBData';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { aminraService, selectableTaxYears } from '../aminra/aminra.service';
//import { StateresidencyService } from './stateresidency.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Visit } from '../aminra/visit/Visit';

//create list of years for dropdown
const currentYear = new Date().getFullYear()
var yearList = [];
for (var i = currentYear+1; i >= currentYear-40; i--) {
    yearList.push(i); 
}

class MnrLocationEntry {
  public country: string;
  public livedabode: string;
  public keptabode: string;
  public dayslived: number;
  public usincome: string;
  public status: string;
  public entry: moment.Moment;
  public exit: moment.Moment;
  public entryraw: NgbDateStruct;
  public exitraw: NgbDateStruct;
  public entryout: string;
  public exitout: string;
  public id: number;

  constructor(
  )  {}
}

@Component({
  selector: 'app-stateresidencyComponent',
  templateUrl: './stateresidency.component.html',
  styleUrls: ['./stateresidency.component.scss']
})


export class StateresidencyComponent implements OnInit {
  // Variables for controlling whether messages are shown to the user, error messages or status
  showNextPageContinueButton: boolean;
  showNewResidencyCheckHelpText = false;
  showOverlapError = false;
  showAbodeError = false;
  showIncompleteError = false;
  showEndDateCaveat = false;
  residencychecksHaveErrors = false;
  showBoundingError = false;
  showStatus = false;
  PrintMode = false;
  visits: Visit;
  entry2: NgbDateStruct;
  exit2: NgbDateStruct;

  // Variables holding information
  taxYear: any;
  navFrompg: boolean = true;
  DateChecks: MnrLocationEntry[] = [];
  selectableYears = yearList;
  selectableCountries = ["Foreign Country"].concat(stateset);
  selectableChoices = binolean;
  selectedYear: number;
  iamnraDateEntry: any;
  iamnraDateExit: any;
  //Residential day counts;
  presentcount: number;
  notprescount: number;
  livedcount: number;
  maintcount: number;
  abodecount: number;
  daycount: number;
  // Counts for entries of residence
  minncount: number;
  statecount: number;
  othercount: number;
  sortedVisits: Visit[];

  // Final result state identifier
  finalres: number;

  targetres: number;
  failstate: string;


  // Used to give each added residencycheck a unique id
  residencycheckCounter = 0;
  dateCheckCounter = 0;
  showUnexpectedErrorMessage: boolean;

  constructor(
    private aminra_Service: aminraService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetection: ChangeDetectorRef
    ) { }

  ngOnInit() {
    if (sessionStorage.pg == 'pgTrue') {
      this.navFrompg = true;
    }
    else {
      this.navFrompg = false;
    }
    this.navFrompg = false;
    this.selectedYear = this.selectableYears[2];
    this.taxYear = selectableTaxYears[2];
    //this.addNewLocationCheck();
    this.showNextPageContinueButton = true;
    this.sortedVisits = this.aminra_Service.getSortedVisits();
    console.log(this.sortedVisits);
    //this.entry2 = {year: 2022, month: 12, day: 3};

    for (var i = 1; i < Number(sessionStorage.stateResidencyVisitCount) + 1; i++) {
      //this.iamnraDateEntry = new NgbDate(parseInt(sessionStorage.stateResidencyVisitEntry.slice[-4]), parseInt(sessionStorage.stateResidencyVisitEntry.slice(3,5)), parseInt(sessionStorage.stateResidencyVisitEntry.slice(0,2)));
      //this.iamnraDateExit = new NgbDate(parseInt(sessionStorage.stateResidencyVisitExit.slice[-4]), parseInt(sessionStorage.stateResidencyVisitExit.slice(3,5)), parseInt(sessionStorage.stateResidencyVisitExit.slice(0,2)))
      //this.iamnraDateEntry = {year: 2022, month: 12, day: 3}
      //this.iamnraDateExit = {year: 2022, month: 12, day: 23}
      this.entry2 = {
        year: parseInt(sessionStorage['stateResidencyVisitEntry' + i].slice(-4)),
        month: parseInt(sessionStorage['stateResidencyVisitEntry' + i].slice(0,2)),
        day: parseInt(sessionStorage['stateResidencyVisitEntry' + i].slice(3,5))
      }
      this.exit2 = {
        year: parseInt(sessionStorage['stateResidencyVisitExit' + i].slice(-4)),
        month: parseInt(sessionStorage['stateResidencyVisitExit' + i].slice(0,2)),
        day: parseInt(sessionStorage['stateResidencyVisitExit' + i].slice(3,5))
      }
      //this.addNewLocationCheck();
      this.DateChecks.push({
        country: null,
        livedabode: null,
        keptabode: null,
        dayslived: null,
        usincome: null,
        status: "null",
        entry: null, //this.iamnraDateEntry,
        exit: null, //this.iamnraDateExit,
        entryraw: null,
        exitraw: null,
        entryout: null,
        exitout: null,
        id: this.dateCheckCounter++
        });
    }
  
  // moment(sessionStorage.stateResidencyVisitEntry, 'MM/DD/YYYY'),
  //moment(sessionStorage.stateResidencyVisitExit, 'MM/DD/YYYY'),
  
  }



  addNewLocationCheck() {
    this.showUnexpectedErrorMessage = false;
    if (this.canAddNewDateCheck()) {
      this.showNewResidencyCheckHelpText = false;
      this.showEndDateCaveat = false;
      this.DateChecks.push({
      country: null,
      livedabode: null,
      keptabode: null,
      dayslived: null,
      usincome: null,
      status: "null",
      entry: null,
      exit: null,
      entryraw: null,
      exitraw: null,
      entryout: null,
      exitout: null,
      id: this.dateCheckCounter++
      });
    }
    else {
      this.showNewResidencyCheckHelpText = true;
    }
  }

  entryDateChangeNew(index) {
    this.finalres = 0;

    this.DateChecks[index].dayslived = 0;
    try {
      console.log('foobar...');
      if (this.DateChecks[index].entryraw && !(typeof this.DateChecks[index].entryraw === 'string')) {
        this.DateChecks[index].entry = moment();
        // this.DateChecks[index].entryraw.month--;
        var temp = this.DateChecks[index].entryraw.month - 1;
        this.DateChecks[index].entry.month(temp);
        this.DateChecks[index].entry.date(this.DateChecks[index].entryraw.day);
        this.DateChecks[index].entry.year(this.DateChecks[index].entryraw.year);
        this.DateChecks[index].entryout = this.DateChecks[index].entryraw.month+"/"+this.DateChecks[index].entryraw.day+"/"+this.DateChecks[index].entryraw.year;
        this.DateChecks[index].status = this.DateChecks[index].entry.dayOfYear()+"/"+this.DateChecks[index].exit.dayOfYear();
      } else if (this.DateChecks[index].entryraw && (typeof this.DateChecks[index].entryraw === 'string')) {
        this.DateChecks[index].entry = moment(this.DateChecks[index].entryraw, 'MM/DD/YYYY', true);
      } else {
        this.DateChecks[index].entry = null;
      }
    } catch (e) { }
    console.log('foo...');
    // this.setResidencyCheckError();
    // this.residencycheck.dayslived = 5;
    // this.residencycheckChanged.emit(this.residencycheck);
    if (this.canAddNewDateCheck()) {
      this.failstate = "RunninEntry";
      this.dayRunnerLite();
      this.failstate = "FinishEntry";
    }
    this.failstate = "PRunninEntry";
    // this.onResidencyCheckChanged(this.DateChecks[index], index);
  }

  exitDateChangeNew(index) {
    this.finalres = 0;

    this.DateChecks[index].dayslived = 0;
    this.failstate = "PRunninExit";
    try {
      console.log('foobar...');
      if (this.DateChecks[index].exitraw && !(typeof this.DateChecks[index].exitraw === 'string')) {
        this.DateChecks[index].exit = moment();
        // this.DateChecks[index].exitraw.month--;
        var temp = this.DateChecks[index].exitraw.month - 1;
        this.DateChecks[index].exit.month(temp);
        this.DateChecks[index].exit.date(this.DateChecks[index].exitraw.day);
        this.DateChecks[index].exit.year(this.DateChecks[index].exitraw.year);
        this.DateChecks[index].exitout = this.DateChecks[index].exitraw.month+"/"+this.DateChecks[index].exitraw.day+"/"+this.DateChecks[index].exitraw.year;
        this.DateChecks[index].status = this.DateChecks[index].entry.dayOfYear()+"/"+this.DateChecks[index].exit.dayOfYear();
      } else if (this.DateChecks[index].exitraw && (typeof this.DateChecks[index].exitraw === 'string')) {
        this.DateChecks[index].exit = moment(this.DateChecks[index].exitraw, 'MM/DD/YYYY', true);
      } else {
        this.DateChecks[index].exit = null;
      }
    } catch (e) { }
    // this.setResidencyCheckError();
    // this.residencycheck.dayslived = 5;
    // this.residencycheckChanged.emit(this.residencycheck);
    this.failstate = "PRunninExit";
    if (this.canAddNewDateCheck()) {
      this.failstate = "RunninExit";
      this.dayRunnerLite();
      this.failstate = "FinishExit";
    }
    console.log('foo...');
    // this.onResidencyCheckChanged(this.DateChecks[index], index);
  }

  checkDateOverlap() {
    var temp = this.DateChecks;
    var sortedArr = null;
    sortedArr = temp.sort((a, b) => (a.entry.dayOfYear() < b.entry.dayOfYear() ? -1 : 1));
    for (let index = 1; index < sortedArr.length; index++) {
      const previous = sortedArr[index - 1];
      const current = sortedArr[index];

      return (previous.exit.dayOfYear() >= current.entry.dayOfYear());
    }
    return false;
  }

  canAddNewDateCheck() {
    let numberOfEmptyExitDates = 0;
    this.showBoundingError = false;
    this.showOverlapError = false;
    for (const residencycheck of this.DateChecks) {
      if (residencycheck.country === null || residencycheck.entry === null) {
        this.failstate = "State 1";
        if (residencycheck.country === null) {
          this.failstate = "State 1 country";
        }
        else {
          this.failstate = "State 1 entry";
        }
        return false;
      }
      else if (residencycheck.country == "Minnesota" && residencycheck.livedabode === null) {
        this.showAbodeError = true;
        return false;
      }
      else if (residencycheck.exit.year() != this.selectedYear || residencycheck.entry.year() != this.selectedYear) {
        this.showBoundingError = true;
        return false;
      }
      else if (residencycheck.country != "Minnesota" && residencycheck.keptabode === null) {
        this.showAbodeError = true;
        return false;
      }

      else {
        if (residencycheck.exit === null) {
          numberOfEmptyExitDates++;
          if (numberOfEmptyExitDates > 1) {
            this.failstate = "State 2";
            return false;
          }
        }
      }
    }
    if (this.checkDateOverlap()) {
      this.showOverlapError = true;
      this.failstate = "State 3";
      return false;
    }
    this.showOverlapError = false;
    this.failstate = "State 5";
    return true;
  }

  onResidencyCheckChangedN(residencycheck, index) {
    this.finalres = 0;
    this.failstate = "PreCC";
    if (this.canAddNewDateCheck()) {
      this.dayRunnerLite();
    }
    this.failstate = "PostCC";
    this.showUnexpectedErrorMessage = false;
    this.showStatus = false;
    this.showAbodeError = false;
    this.showNewResidencyCheckHelpText = false;
    this.showEndDateCaveat = false;
    this.DateChecks[index] = _.cloneDeep(residencycheck);
  }

  onResidencyCheckRemoved(residencycheck, index) {
    this.finalres = 0;
    this.showUnexpectedErrorMessage = false;
    this.showStatus = false;
    this.showNewResidencyCheckHelpText = false;
    this.showAbodeError = false;
    this.showEndDateCaveat = false;
    this.DateChecks.splice(index, 1);
    if (this.DateChecks.length === 0) {
      this.canAddNewDateCheck();
    }
  }

  removeResidencyCheck(index) {
    this.finalres = 0;
    var temp = this.DateChecks.splice(index, 1);
    this.onResidencyCheckRemoved(temp, index)

  }

  removedataentry(index) {
    this.finalres = 0;
    var temp = this.DateChecks.splice(index, 1);
    // this.onResidencyCheckRemoved(temp, index)

  }

  // entrytrigger(index) {
  //   this.residencychecks[index].ResidencyCheck;
  // }

  trackIndex(index, item) {
    return item.id;
  }

  clearResidencyChecks() {
    this.finalres = 0;
    this.residencychecksHaveErrors = false;
    this.showOverlapError = false;
    this.showStatus = false;
    this.showEndDateCaveat = false;
    this.DateChecks = [];
    this.showUnexpectedErrorMessage = false;
    this.addNewLocationCheck();
  }

  dayRunner() {
    this.presentcount = 0;
    this.notprescount = 0;
    this.livedcount = 0;
    this.maintcount = 0;
    this.minncount = 0;
    this.statecount = 0;
    this.othercount = 0;


    for (let index = 0; index < this.DateChecks.length; index++) {

      const element = this.DateChecks[index];
      var firstDate = element.entry;
      var secondDate = element.exit;

      this.DateChecks[index].dayslived = secondDate.endOf('day').diff(firstDate.startOf('day'), 'days') + 1;
      this.targetres = this.DateChecks[index].dayslived;
      if (this.DateChecks[index].country != "Minnesota") {
        if (stateset.includes(this.DateChecks[index].country)) {
          this.statecount += this.DateChecks[index].dayslived;
        }
        else {
          this.othercount += this.DateChecks[index].dayslived;

        }
        this.DateChecks[index].status = "N"
        this.notprescount += this.DateChecks[index].dayslived;

        if (this.DateChecks[index].keptabode == "Yes") {
          this.maintcount += this.DateChecks[index].dayslived;
        }

      }
      else {
        this.minncount += this.DateChecks[index].dayslived;

        this.DateChecks[index].status = "N"
        this.presentcount += this.DateChecks[index].dayslived;

        if (this.DateChecks[index].livedabode == "Yes") {
        this.DateChecks[index].status = "R"
          this.livedcount += this.DateChecks[index].dayslived;
        }
        else if (this.DateChecks[index].keptabode == "Yes") {
          this.DateChecks[index].status = "R"
          this.maintcount += this.DateChecks[index].dayslived;
        }



      }


    }

    this.abodecount = this.livedcount + this.maintcount;
    this.finalres = 0;
    this.resState();
  }

  resState() {
    if ((this.minncount) < 183) {

      if (this.statecount == 0) {
        this.finalres = 1;
      }
      else {
        this.finalres = 2;

      }


    }
    else {
      if ((this.maintcount + this.abodecount) >= this.daycount) {
        this.finalres = 5;
        for (let index = 0; index < this.DateChecks.length; index++) {
          this.DateChecks[index].status = "R";
        }
    
      }
      else if ((this.maintcount + this.abodecount) == 0) {
        this.finalres = 1;
      }
      else if (this.statecount == 0) {
        this.finalres = 3;
      }
      else {
        this.finalres = 4;

      }


    }
  }

  dayRunnerLite() {
    this.daycount = 0;

    for (let index = 0; index < this.DateChecks.length; index++) {

      const element = this.DateChecks[index];
      var firstDate = element.entry;
      var secondDate = element.exit;
      this.DateChecks[index].dayslived = (secondDate.dayOfYear()-firstDate.dayOfYear())+1;
      // this.DateChecks[index].status = secondDate.dayOfYear().toString();
      this.daycount += this.DateChecks[index].dayslived;
    }
  }

  onSubmit() {
    this.showIncompleteError = false;
    this.failstate = "Fin 1";
    if (this.daycount < 365) {
      this.showIncompleteError = true;

    }
    else if (this.canAddNewDateCheck()) {
      this.failstate = "Fin 2";
      this.dayRunner();
      this.failstate = "Fin 3";
      this.showNewResidencyCheckHelpText = false;
      this.showStatus = true;
    }
    else {
      this.failstate = "Fin 4";
      this.showStatus = false;
      this.showNewResidencyCheckHelpText = true;
    }
  }

  pbf() {
    this.failstate = "Fin 1";
  }

  togglePrintMode() {
    if (this.finalres == 0 || this.finalres == null)
      return
    this.PrintMode = !this.PrintMode;
  }

  selectedYearChange() {
    this.daycount = 0;
    this.finalres = 0;

    this.showStatus = false;
  }

  onSubmitGoToNextPage() {
    this.router.navigate(['../mnproptax'], {relativeTo: this.route});
  }

  onSubmitBack() {
    this.router.navigate(['../pg-nr05'], {relativeTo: this.route});
  }
}
