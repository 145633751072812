<nav class="navbar 
            navbar-expand-sm 
            navbar-dark 
            bg-blue
            sticky-top">
    <!--<a routerLink='' style="color: rgb(253, 151, 38);" class="nav-item navbar-brand">Tools Home</a>-->
    <a href="https://www.nrvtap.com/" style="color: rgb(253, 151, 38);" class="nav-item navbar-brand">NRVTAP</a>
    <div>
        <ul class="navbar-nav mr-auto">
        </ul>
    </div>
 </nav>
