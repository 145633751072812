

export var ctbdata = [
    //                                                                                                                                                                                                  SM = Special Messages    
    //  Country Name     
    [ ""                                   ],
    [ "Australia"                          ],
    [ "Austria"                            ],
    [ "Bangladesh"                         ],
    [ "Barbados"                           ],
    [ "Belgium"                            ],
    [ "Bulgaria"                           ],
    [ "Canada"                             ],
    [ "China"                              ],
    [ "Commonwealth of Independent States" ],          
    [ "Cyprus"                             ],
    [ "Czech Republic"                     ],
    [ "Denmark"                            ],
    [ "Egypt"                              ],
    [ "Estonia"                            ],
    [ "Finland"                            ],
    [ "France"                             ],
    [ "Germany"                            ],
    [ "Greece"                             ],
    [ "Hungary"                            ],
    [ "Iceland"                            ],
    [ "India"                              ],
    [ "Indonesia"                          ],
    [ "Ireland"                            ],
    [ "Israel"                             ],
    [ "Italy"                              ],
    [ "Jamaica"                            ],
    [ "Japan"                              ],
    [ "Kazakhstan"                         ],
    [ "Georgia"                            ],
    [ "Korea, South"                       ],
    [ "Latvia"                             ],
    [ "Lithuania"                          ],
    [ "Luxembourg"                         ],
    [ "Malta"                              ],
    [ "Mexico"                             ],
    [ "Morocco"                            ],
    [ "Netherlands"                        ],
    [ "New Zealand"                        ],
    [ "Norway"                             ],
    [ "Pakistan"                           ],
    [ "Philippines"                        ],
    [ "Poland"                             ],
    [ "Portugal"                           ],
    [ "Romania"                            ],
    [ "Russia"                             ],
    [ "Slovak Republic"                    ],
    [ "Slovenia"                           ],
    [ "South Africa"                       ],
    [ "Spain"                              ],
    [ "Sri Lanka"                          ],
    [ "Sweden"                             ],
    [ "Switzerland"                        ],
    [ "Thailand"                           ],
    [ "Trinidad and Tobago"                ],
    [ "Tunisia"                            ],
    [ "Turkey"                             ],
    [ "Ukraine"                            ],
    [ "United Kingdom"                     ],
    [ "Venezuela"                          ],
    ];

    export const binolean=[
        "Yes",
        "No",
    ]

    export const countryindexes=[
        ctbdata[1][0],
        ctbdata[2][0],
        ctbdata[3][0],
        ctbdata[4][0],
        ctbdata[5][0],
        ctbdata[6][0],
        ctbdata[7][0],
        ctbdata[8][0],
        ctbdata[9][0],
       ctbdata[10][0],
       ctbdata[11][0],
       ctbdata[12][0],
       ctbdata[13][0],
       ctbdata[14][0],
       ctbdata[15][0],
       ctbdata[16][0],
       ctbdata[17][0],
       ctbdata[18][0],
       ctbdata[19][0],
       ctbdata[20][0],
       ctbdata[21][0],
       ctbdata[22][0],
       ctbdata[23][0],
       ctbdata[24][0],
       ctbdata[25][0],
       ctbdata[26][0],
       ctbdata[27][0],
       ctbdata[28][0],
       ctbdata[29][0],
       ctbdata[30][0],
       ctbdata[31][0],
       ctbdata[32][0],
       ctbdata[33][0],
       ctbdata[34][0],
       ctbdata[35][0],
       ctbdata[36][0],
       ctbdata[37][0],
       ctbdata[38][0],
       ctbdata[39][0],
       ctbdata[40][0],
       ctbdata[41][0],
       ctbdata[42][0],
       ctbdata[43][0],
       ctbdata[44][0],
       ctbdata[45][0],
       ctbdata[46][0],
       ctbdata[47][0],
       ctbdata[48][0],
       ctbdata[49][0],
       ctbdata[50][0],
       ctbdata[51][0],
       ctbdata[52][0],
       ctbdata[53][0],
       ctbdata[54][0],
       ctbdata[55][0],
       ctbdata[56][0],
       ctbdata[57][0],
       ctbdata[58][0],
       ctbdata[59][0],
    ]

    export const stateset=
        [   
            "Minnesota",
            "Alabama",
            "Alaska",
            "Arizona",
            "Arkansas",
            "California",
            "Colorado",
            "Connecticut",
            "Delaware",
            "Florida",
            "Georgia",
            "Hawaii",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Kansas",
            "Kentucky",
            "Louisiana",
            "Maine",
            "Maryland",
            "Massachusetts",
            "Michigan",
            "Mississippi",
            "Missouri",
            "Montana",
            "Nebraska",
            "Nevada",
            "New Hampshire",
            "New Jersey",
            "New Mexico",
            "New York",
            "North Carolina",
            "North Dakota",
            "Ohio",
            "Oklahoma",
            "Oregon",
            "Pennsylvania",
            "Rhode Island",
            "South Carolina",
            "South Dakota",
            "Tennessee",
            "Texas",
            "Utah",
            "Vermont",
            "Virginia",
            "Washington",
            "West Virginia",
            "Wisconsin",
            "Wyoming"
    ]