import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-pg-nr02',
  templateUrl: './pg-nr02.component.html',
  styleUrls: ['./pg-nr02.component.scss'],
})


export class PGnr02Component implements OnInit {
  selectYesNo = ['Yes', 'No']
  selectTaxResidencyStatus = ['Resident', 'Nonresident', 'Dual Status']
  
  //Variables
  taxYear: any;
  married: boolean = null;
  marriedAnswer: string = null;
  spouseCitizen: boolean = null;
  spouseCitizenAnswer: string = null;
  spouseTaxResidency: string = null;
  spouseSSNITIN: boolean = null;
  spouseSSNITINAnswer: string = null;
  showMarriedQ: boolean = null;
  showSpouseFJMQQuestion: boolean = null;
  spousePresentFJMQVisaAnswer: string = null;
  filingTypeSpouseCitizenChecked: boolean = null;
  filingTypeSpouseCitizenChoice: string = null;
  filingTypeSpouseTaxResidencyResidentChecked: boolean = null;
  filingTypeSpouseTaxResidencyResidentChoice: string = null;
  filingTypeSpouseTaxResidencyDSChecked: boolean = null;
  filingTypeSpouseTaxResidencyDSChoice: string = null;
  showfilingTypeSpouseTaxResidencyDSSeparate: boolean = false;
  showfilingJointSpouseCitizenText: boolean = false;
  showfilingJointSpouseNonCitizenResidentText: boolean = false;
  showfilingJointSpouseNonCitizenDSText: boolean = false;
  showSpouseCitizenQ: boolean = null;
  showSpouseTaxResQ: boolean = null;
  showSpouseSSNITINQ: boolean = null;
  showOutOfScope: boolean = null;
  showNextPageContinueButton: boolean = false;
  showResidentGuideContinueButton: boolean = false;
  taxResCountry: string = null;
  pageNotActive: boolean = null;


  formFilingTypeSpouseCitizen = new FormGroup({
    filingTypeSpouseCitizen: new FormControl('', Validators.required)
  });

  formFilingTypeSpouseTaxResidencyResident = new FormGroup({
    filingTypeSpouseTaxResidencyResident: new FormControl('', Validators.required)
  });

  formFilingTypeSpouseTaxResidencyDS = new FormGroup({
    filingTypeSpouseTaxResidencyDS: new FormControl('', Validators.required)
  });

  submitFormFilingSpouseCitizen() {}
  changeFilingTypeSpouseCitizen(e) {
    this.filingTypeSpouseCitizenChecked = true;
    this.filingTypeSpouseCitizenChoice = e.target.value;
    if (this.filingTypeSpouseCitizenChoice == 'separate') {
      sessionStorage.spouseFilingType = 'Separate';
      this.showOutOfScope = false;
      this.showResidentGuideContinueButton = false;
      this.showfilingJointSpouseCitizenText = false;
      this.showNextPageContinueButton = true;
    }
    else if (this.filingTypeSpouseCitizenChoice == 'joint') {
      sessionStorage.spouseFilingType = 'Joint';
      this.showOutOfScope = false;
      this.showResidentGuideContinueButton = false;
      this.showNextPageContinueButton = false;
      this.showfilingJointSpouseCitizenText = true;
    }
  }
  get fFilingTypeSpouseCitizen() {
    return this.formFilingTypeSpouseCitizen.controls;
  }

  submitFormFilingSpouseTaxResidencyResident() {}
  changeFilingTypeSpouseTaxResidencyResident(e) {
    this.filingTypeSpouseTaxResidencyResidentChecked = true;
    this.filingTypeSpouseTaxResidencyResidentChoice = e.target.value;
    if (this.filingTypeSpouseTaxResidencyResidentChoice == 'separate') {
      sessionStorage.spouseFilingType = 'Separate';
      this.showOutOfScope = false;
      this.showSpouseSSNITINQ = true;
      this.showNextPageContinueButton = false;
      this.showResidentGuideContinueButton = false;
      this.showfilingJointSpouseNonCitizenResidentText = false;
      this.spouseSSNITIN = null;
      this.spouseSSNITINAnswer = null;
    }
    else if (this.filingTypeSpouseTaxResidencyResidentChoice == 'joint') {
      sessionStorage.spouseFilingType = 'Joint';
      this.showOutOfScope = true;
      this.showSpouseSSNITINQ = false;
      this.showNextPageContinueButton = false;
      this.showfilingJointSpouseNonCitizenResidentText = true;
      this.showResidentGuideContinueButton = false;
      this.spouseSSNITIN = null;
      this.spouseSSNITINAnswer = null;
    }
  }
  get fFilingTypeSpouseTaxResidencyResident() {
    return this.formFilingTypeSpouseTaxResidencyResident.controls;
  }
  
  submitFormFilingSpouseTaxResidencyDS() {}
  changeFilingTypeSpouseTaxResidencyDS(e) {
    this.filingTypeSpouseTaxResidencyDSChecked = true;
    this.filingTypeSpouseTaxResidencyDSChoice = e.target.value;
    if (this.filingTypeSpouseTaxResidencyDSChoice == 'separate') {
      sessionStorage.spouseFilingType = 'Separate';
      this.showfilingJointSpouseNonCitizenDSText = false;
      this.showOutOfScope = false;
      this.showfilingTypeSpouseTaxResidencyDSSeparate = true;
      this.showSpouseSSNITINQ = true;
      this.showNextPageContinueButton = false;
      this.showResidentGuideContinueButton = false;
      this.spouseSSNITIN = true;
      this.spouseSSNITINAnswer = null;
    }
    else if (this.filingTypeSpouseTaxResidencyDSChoice == 'joint') {
      sessionStorage.spouseFilingType = 'Joint';
      this.showfilingJointSpouseNonCitizenDSText = true;
      this.showOutOfScope = true;
      this.showfilingTypeSpouseTaxResidencyDSSeparate = false;
      this.showSpouseSSNITINQ = false;
      this.showNextPageContinueButton = false;
      this.showResidentGuideContinueButton = false;
      this.spouseSSNITIN = false;
      this.spouseSSNITINAnswer = null;
    }
  }
  get fFilingTypeSpouseTaxResidencyDS() {
    return this.formFilingTypeSpouseTaxResidencyDS.controls;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.showMarriedQ = null;
    this.showSpouseCitizenQ = false;
    this.showSpouseTaxResQ = false;
    this.showSpouseFJMQQuestion = false;
    this.showSpouseSSNITINQ = false;
    this.showNextPageContinueButton = false;
    this.showResidentGuideContinueButton = false;
    this.showfilingTypeSpouseTaxResidencyDSSeparate = false;
    this.showfilingJointSpouseCitizenText = false;
    this.showfilingJointSpouseNonCitizenResidentText = false;
    this.showfilingJointSpouseNonCitizenDSText = false;
    this.showOutOfScope = false;
    this.taxYear = sessionStorage.taxYear;
    this.married = null;
    this.spouseCitizen = null;
    sessionStorage.married = '';
    sessionStorage.spouseCitizen = '';
    sessionStorage.spouseTaxResidency = '';
    sessionStorage.spousePresentFJMQVisa = '';
    sessionStorage.spouseSSNITIN = '';
  }

  marriedChange() {
    sessionStorage.married = this.marriedAnswer;
    if (this.marriedAnswer == 'Yes') {
      this.married = true;
      this.spouseCitizen = null;
      this.showSpouseCitizenQ = true;
      this.showNextPageContinueButton = false;
    }
    else if (this.marriedAnswer == 'No') {
      this.married = false;
      this.showSpouseCitizenQ = false;
      this.showNextPageContinueButton = true;
    }
    this.showfilingJointSpouseCitizenText = false;
    this.showfilingJointSpouseNonCitizenResidentText = false;
    this.showfilingJointSpouseNonCitizenDSText = false;
    this.showSpouseTaxResQ = false;
    this.showSpouseFJMQQuestion = false;
    this.showSpouseSSNITINQ = false;
    this.spouseCitizen = null;
    this.spouseTaxResidency = null;
    this.spouseSSNITIN = null;
    this.showResidentGuideContinueButton = false;
  }

  spouseCitizenChange() {
    sessionStorage.spouseCitizen = this.spouseCitizenAnswer;
    if (this.spouseCitizenAnswer == 'Yes') {
      sessionStorage.spouseSSNITIN = 'Yes';
      this.spouseCitizen = true;
      this.showSpouseTaxResQ = false;
    }
    else if (this.spouseCitizenAnswer == 'No') {
      this.spouseCitizen = false;
      this.showSpouseTaxResQ = true;
    }
    this.showSpouseSSNITINQ = false;
    this.showSpouseFJMQQuestion = false;
    this.showNextPageContinueButton = false;
    this.showResidentGuideContinueButton = false;
    this.showfilingJointSpouseCitizenText = false;
    this.showfilingJointSpouseNonCitizenResidentText = false;
    this.showfilingJointSpouseNonCitizenDSText = false;
    this.spouseTaxResidency = null;
    this.spouseSSNITIN = null;
    this.spouseSSNITINAnswer = null;
  }

  spouseTaxResidencyChange() {
    sessionStorage.spouseTaxResidency = this.spouseTaxResidency;
    if (this.spouseTaxResidency == "Nonresident") {
      this.showfilingJointSpouseNonCitizenDSText = false
      this.showfilingJointSpouseNonCitizenResidentText = false
    }
    if (this.spouseTaxResidency == "Resident") {
    }
    if (this.spouseTaxResidency == "Dual Status") {
    }
    this.showOutOfScope = false
    this.showSpouseSSNITINQ = false;
    this.showNextPageContinueButton = false;
    this.showResidentGuideContinueButton = false;
    this.showfilingTypeSpouseTaxResidencyDSSeparate = false;
    this.spouseSSNITIN = null;
    this.spouseSSNITINAnswer = null;
    this.formFilingTypeSpouseTaxResidencyResident.reset()
    this.formFilingTypeSpouseTaxResidencyDS.reset()
  }

  spousePresentFJMQVisaChange() {
    sessionStorage.spousePresentFJMQVisa = this.spousePresentFJMQVisaAnswer;
    this.showSpouseSSNITINQ = true;
  }

  spouseSSNITINChange() {
    sessionStorage.spouseSSNITIN = this.spouseSSNITINAnswer;
    if (this.spouseSSNITINAnswer == 'Yes') {
      this.spouseSSNITIN = true;
      this.showNextPageContinueButton = true;
    }
    else if (this.spouseSSNITINAnswer == 'No') {
      this.spouseSSNITIN = false;
      this.showNextPageContinueButton = true;
    }
  }
  
  onSubmitGoToNextPage() {
    //Check for Treaty Benefits
    //if (studentVisas.includes(sessionStorage.visaType)) {}
    if (sessionStorage.taxResidentCountry == 'Canada'
      || sessionStorage.taxResidentCountry == 'Mexico'
      || sessionStorage.taxResidentCountry == 'India'
      || sessionStorage.taxResidentCountry == 'Korea, South') {
        sessionStorage.navDir = 'forward'
        this.router.navigate(['../pg-nr03'], {relativeTo: this.route});
        }
    else {
      sessionStorage.navDir = 'forward'
      this.router.navigate(['../pg-nr04'], {relativeTo: this.route});
    }
  }

  onSubmitGoToaminra() {
    this.router.navigate([]).then((result) => {
      window.open('../aminra', '_blank');
    });
  }

  onSubmitBack() {
    sessionStorage.prevPage = 'pg-nr02'
    sessionStorage.navDir = 'backward'
    this.router.navigate(['../aminra'], {relativeTo: this.route});
  }
}
