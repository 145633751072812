import * as _ from 'lodash';

import { Visit } from './visit/Visit';

export class YearSummary {
    private year: number;
    public visits: Visit[];

    constructor(year: number, visits: Visit[] = []) {
        this.year = year;
        this.visits = visits;
    }

    getYear(): number {
        return this.year;
    }

    getVisits(): Visit[] {
        return this.visits;
    }

    addVisit(visit: Visit) {
        this.visits.push(visit);
    }

    sortVisits() {
        this.visits.sort((visitA: Visit, visitB: Visit) => {
            return visitA.getEntry().diff(visitB.getEntry());
        });
    }

    getNonExemptVisits(): Visit[] {
        return _.filter(this.visits, (visit: Visit) => {
            return visit.getNonExemptYears().includes(this.year);
        });
    }

    getNumberOfNonExemptDays(): number {
        let numberOfNonExemptDays = 0;
        this.visits.forEach((visit: Visit) => {
            numberOfNonExemptDays += visit.getNumberOfNonExemptDaysInYear(this.year);
        });
        return numberOfNonExemptDays;
    }

    getNumberOfExemptDays(): number {
        let FJMQVisaList: string[]  = ['F-1 Student','J-1 Student','J-1 Non-Student','F-2 Student','J-2 Student','J-2 Non-Student','M-1 Student','M-2 Student','Q-1 Student','Q-1 Non-Student','Q-2 Student','Q-2 Non-Student',]
        let FJMQSVisaList: string[] = ['F-1 Student','J-1 Student','F-2 Student','J-2 Student','M-1 Student','M-2 Student','Q-1 Student','Q-2 Student']
        let JQNSVisaList: string[]  = ['J-1 Non-Student','J-2 Non-Student','Q-1 Non-Student','Q-2 Non-Student']
        let numberOfExemptDays = 0;
        this.visits.forEach((visit: Visit) => {
            let as: string = String(visit.getVisa())
            if (!visit.getNonExemptYears().includes(this.year)) {
                numberOfExemptDays += visit.getNumberOfPresentDaysInYear(this.year);
                if (visit.getNumberOfPresentDaysInYear(this.year) > 0 && FJMQVisaList.includes (String(visit.getVisa()))) {
                    sessionStorage.exemptDaysFJMQVisa = 'Yes'}
                if (visit.getNumberOfPresentDaysInYear(this.year) > 0 && FJMQSVisaList.includes(String(visit.getVisa()))) {
                    sessionStorage.exemptDaysFJMQSVisa = 'Yes'}
                if (visit.getNumberOfPresentDaysInYear(this.year) > 0 && JQNSVisaList.includes (String(visit.getVisa()))) {
                    sessionStorage.exemptDaysJQNSVisa = 'Yes'}
            }
        });
        return numberOfExemptDays;
    }

    getNumberOfPresentDays(): number {
        let numberOfPresentDays = 0;
        this.visits.forEach((visit: Visit) => {
            numberOfPresentDays += visit.getNumberOfPresentDaysInYear(this.year);
        });
        return numberOfPresentDays;
    }
}
