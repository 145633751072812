/**
 * This file contains all the meta data needed for Visas.
 */

 /**
  * All Visas that we currently support
  */
export enum Visa {
    F1 = 'F-1 Student',
    J1Student = 'J-1 Student',
    J1NonStudent = 'J-1 Non-Student',
    F2 = 'F-2 Student',
    J2Student = 'J-2 Student',
    J2NonStudent = 'J-2 Non-Student',
    M1 = 'M-1 Student',
    M2 = 'M-2 Student',
    Q1Student = 'Q-1 Student',
    Q1NonStudent = 'Q-1 Non-Student',
    Q2Student = 'Q-2 Student',
    Q2NonStudent = 'Q-2 Non-Student',
    NonExemptVisa = 'Non-exempt visa (B1/B2, TN, H1-b, etc.)',
    OtherExemptVisa = 'Other Exempt visa (A, G, NATO, etc.)'
}

/**
 * Different Categories of Visas used by the NRA Tool
 */
export enum VisaType {
    Student,
    NonStudent,
    NonExempt,
    Exempt
}

/**
 * Visas that are considered to be Student visas
 */
export const studentVisas: Visa[] = [
    Visa.F1,
    Visa.F2,
    Visa.J1Student,
    Visa.J2Student,
    Visa.M1,
    Visa.M2,
    Visa.Q1Student,
    Visa.Q2Student
];

/**
 * Visas that are considered to be Teacher/Trainee/Student visas
 */
export const teacherTraineeStudentVisas: Visa[] = [
    Visa.F1,
    Visa.F2,
    Visa.J1Student,
    Visa.J1NonStudent,
    Visa.J2Student,
    Visa.J2NonStudent,
    Visa.M1,
    Visa.M2,
    Visa.Q1Student,
    Visa.Q1NonStudent,
    Visa.Q2Student,
    Visa.Q2NonStudent
];

/**
 * Visas that are considered to be Teacher/Trainee visas
 */
 export const teacherTraineeVisas: Visa[] = [
    Visa.J1NonStudent,
    Visa.J2NonStudent,
    Visa.Q1NonStudent,
    Visa.Q2NonStudent
];

/**
 * Visas that are F or J visas (for form 8843 check)
 */
 export const ForJVisas: Visa[] = [
    Visa.F1,
    Visa.F2,
    Visa.J1Student,
    Visa.J1NonStudent,
    Visa.J2Student,
    Visa.J2NonStudent,
];