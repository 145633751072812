<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR04.jpg" alt='Progress Bar 5 of 10'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide - U.S. Income</h3>
            <!--<p>Comment</p>-->
            <hr class="custom-line">
            <p>&nbsp;</p>
        </div>
    </div>


    <div class="row">
        <div class="col-sm-7 col-lg-7">
            <label>Do you have a Social Security Number (SSN) or Individual Taxpayer Identification Number (ITIN)?</label>
        </div>
        <div class="col-sm-2 col-lg-2">
            <ng-select id="SSNITIN" [items]="selectYesNo" [(ngModel)]="SSNITIN" (ngModelChange)="SSNITINChange()" placeholder="Select" [clearable]="false"></ng-select>
        </div> 
    </div>

    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>

    <div *ngIf="showUSIncomeQ">
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>What types of U.S. income did you receive in {{taxYear}}?  Check all that apply.</label>
            </div>
            <ul class="checkbox-items">
                <li *ngFor="let item of incomeCheckboxesDataList">
                    <input type="checkbox" [(ngModel)]="item.isChecked" (change)="changeCheckBoxSelection()">{{item.label}}
                </li>
                </ul>
        </div>
    </div>

    <div *ngIf="showFedTaxWithheldQ">
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label for="FedTaxWithheld">Did you have any federal tax withheld in Box 3 on your 1099-INT or on your 1042S for interest?</label>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="FedTaxWithheld" [items]="selectYesNo" [(ngModel)]="FedTaxWithheld" (ngModelChange)="FedTaxWithheldChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div> 
        </div>      
    </div>

    <div *ngIf="showInterestPayorQ">
        <div class="row">
            <div class="col-sm-7 col-lg-7">
                <label>Who did you receive interest from?</label>
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="interestPayor" [items]="selectInterestPayor" [(ngModel)]="interestPayor" (ngModelChange)="interestPayorChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div> 
        </div>      
    </div>






    <div *ngIf="showIncomeNoneError" class="col-12">
        <div class="row">
            <div class="invalid-feedback d-block">If "No U.S. Income" is checked then you cannot have any other incomes also checked.</div>
        </div>
    </div>

    <div *ngIf="showFedTaxWithheldError || showSSNITINError" class="col-12">
        <div class="row">
            <div class="invalid-feedback d-block">You must select a response.</div>
        </div>
    </div>

    <div *ngIf="(formChecked && !showIncomeNoneError && showContinueButton)">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div>
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitContinue()">Continue</button>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p><small>If you aren't sure how to respond you can ask us at <a href="mailto:taxassistance@nrvtap.com">taxassistance@nrvtap.com</a>.</small></p>
        </div>
    </div>


</div>
