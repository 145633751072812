<div class="container page-with-footer">
    <div class="row">
        <div class="col-8">
            <h3>Personalized Guide - Introduction</h3>
            <hr class="custom-line">
            <p>&nbsp;</p>
            <p>This tool has a series of interview questions that will generate a customized guide that you can use to prepare your tax return using the
                free software on our site.</p>  
            <p>When you are finished with the interview, you will be able to download a pdf that contains your customized guide.  No personally identifiable 
                information will be requested - there will be lots of yes/no questions, along with generic visa type, country, dates, as well as document 
                types and amounts.  You will not enter your name, email, address, tax ID, phone number, or any other personally identifiable data.</p>
            <p>Your personalized guide will be a step-by-step, screenshot-by-screenshot guide that will walk you through preparing your tax return 
                using the free TaxSlayer software available on our member site.</p>
            <p>NOTE: If you do not create your TaxSlayer account from the link on our member pages, TaxSlayer will likely want to charge you for your tax return.  
                If this happens, you will need to delete your initial account and create a new one using the link on our website.</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitStart()">Start My Personalized Guide Interview!</button>
        </div>
    </div>

</div> 