import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pg-nr03',
  templateUrl: './pg-nr03.component.html',
  styleUrls: ['./pg-nr03.component.scss']
})

export class PGnr03Component implements OnInit {

  selectYesNo = ['Yes', 'No']
  selectTaxResidencyStatus = ['Resident', 'Dual Status', 'Nonresident']

  //Variables
  childrenAnswer: string = null;
  showContinueButton: boolean;
  showChildrenForm: boolean;
  formChecked: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {
    this.showContinueButton = false;
    this.childrenAnswer = null;
    this.showChildrenForm = false;
    this.formChecked = false;
    this.fetchSelectedItems()
    this.fetchCheckedIDs()
    sessionStorage.children = null;
    sessionStorage.childBornUS = null;
    sessionStorage.childPresentUS = null;
    sessionStorage.childNotPresent = null;
  }


  childrenChange() {
    sessionStorage.children = this.childrenAnswer;
    if (this.childrenAnswer == "No") {
      this.showChildrenForm = false;
      this.showContinueButton = true;
      this.formChecked = false;
      this.setCheckBoxesToUnchecked()
    }
    else if(this.childrenAnswer == "Yes") {
      this.showChildrenForm = true;
      this.showContinueButton = false;
      this.formChecked = false;
      this.setCheckBoxesToUnchecked()
    }
  }

  selectedItemsList = [];
  checkedIDs = [];

  childrenCheckboxesDataList = [
    {
      id: 'C1',
      label: ' Child is a U.S. citizen, permanent resident, or resident alien.',
      isChecked: false
    },
    {
      id: 'C2',
      label: ' Child present in the U.S. with you on a -2 visa for at least some part of ' + sessionStorage.taxYear,
      isChecked: false
    },
    {
      id: 'C3',
      label: ' Child not in the U.S. at all during ' + sessionStorage.taxYear,
      isChecked: false
    }
  ]

  changeCheckBoxSelection() {
    this.fetchSelectedItems()
  }

  fetchSelectedItems() {
    this.formChecked = false;
    this.selectedItemsList = this.childrenCheckboxesDataList.filter((value, index) => {
      if (value.isChecked == true) {
        this.formChecked = true;
      }
      return value.isChecked
    });
  }

  setCheckBoxesToUnchecked() {
    this.formChecked = false;
    this.selectedItemsList = this.childrenCheckboxesDataList.filter((value, index) => {
      if (value.isChecked == true) {
        value.isChecked = false;
      }
      return value.isChecked
    });
  }

  fetchCheckedIDs() {
    this.checkedIDs = []
    this.childrenCheckboxesDataList.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDs.push(value.id);
      }
    });
  }

  onSubmitGoTonr04() {
    if (this.childrenCheckboxesDataList[0].isChecked == true){sessionStorage.childBornUS = "Yes";}
    else {sessionStorage.childBornUS = "No";}
    if (this.childrenCheckboxesDataList[1].isChecked == true){sessionStorage.childPresentUS = "Yes";}
    else {sessionStorage.childPresentUS = "No";}
    if (this.childrenCheckboxesDataList[2].isChecked == true){sessionStorage.childNotPresent = "Yes";}
    else {sessionStorage.childNotPresent = "No";}

    sessionStorage.navDir = 'forward'
    sessionStorage.prevPage = 'pg-nr03'
    this.router.navigate(['../pg-nr04'], {relativeTo: this.route});
  }

  onSubmitBack() {
    sessionStorage.navDir = 'backward'
    sessionStorage.prevPage = 'pg-nr03'
    this.router.navigate(['../pg-nr02'], {relativeTo: this.route});
  }
}