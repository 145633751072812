import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { VisitModel } from './visit/VisitModel';
import { aminraService, NonResidentStatus, selectableTaxYears, selectYesNoChoices } from './aminra.service';
import { Visit } from './visit/Visit';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-aminra',
  templateUrl: './aminra.component.html',
  template: '<ng-select id="foreignIncomeInTaxYear"',
  styleUrls: ['./aminra.component.scss'],
})

export class aminraComponent implements OnInit {
  // Variables for controlling whether messages are shown to the user, error messages or status
  showNewVisitHelpText = false;
  showOverlapError = false;
  showIncomeQuestionsError = false;
  showEndDateCaveat = false;
  showForm8843Caveat = false;
  visitsHaveErrors = false;
  showStatus = false;
  showIncomeQuestions = false;
  fIncTaxYr = false;
  fIncPresYrs = false;
  showDualStatusResidentText = false;
  singleTaxYear: boolean = false;

  // Variables holding information
  visits: VisitModel[] = [];
  taxYear: any;
  nextYear: number;
  selectableYears = selectableTaxYears;
  selectedYear: number;
  nonResidentStatus: NonResidentStatus;
  selectYesNo = selectYesNoChoices;
  foreignIncomeInTaxYear = "Select...";
  foreignIncomeInPresentYears = "Select...";
  foreignPayYears: string;
  foreignIncomeYesYes: Boolean;
  FIncomeQ: boolean;
  navFrompg: boolean;

  // Used to give each added visit a unique id
  visitCounter = 0;
  showUnexpectedErrorMessage: boolean;

  constructor(
    private aminraService: aminraService,
    private router: Router,
    private route: ActivatedRoute,
    ) {}

  ngOnInit() {
    if (sessionStorage.pg == 'pgTrue') {
      this.navFrompg = true;
      this.taxYear = sessionStorage.taxYear;
      this.nextYear = Number(sessionStorage.taxYear) + 1
      this.selectedYear = Number(sessionStorage.taxYear);
    }
    else {
      this.navFrompg = false;
      this.selectedYear = selectableTaxYears[2];
    }
    this.addNewVisit();
  }

  addNewVisit() {
    this.showUnexpectedErrorMessage = false;
    this.showIncomeQuestions = false;
    this.foreignIncomeInTaxYear = "Select..."
    this.foreignIncomeInPresentYears = "Select..."
    if (this.canAddNewVisit()) {
      this.showNewVisitHelpText = false;
      this.showEndDateCaveat = false;
      this.showForm8843Caveat = false;
  
      this.visits.push({
        visa: null,
        entry: null,
        exit: null,
        id: this.visitCounter++
      });
    } else {
      this.showNewVisitHelpText = true;
    }
  }

  canAddNewVisit() {
    let numberOfEmptyExitDates = 0;
    for (const visit of this.visits) {
      if (visit.visa === null || visit.entry === null) {
        return false;
      } else {
        if (visit.exit === null) {
          numberOfEmptyExitDates++;
          if (numberOfEmptyExitDates > 1) {
            return false;
          }
        }
      }
    }
    if (this.aminraService.doVisitsOverlap(this.constructVisits())) {
      this.showOverlapError = true;
      return false;
    }
    return true;
  }

  onVisitChanged(visit, index) {
    this.showUnexpectedErrorMessage = false;
    this.showStatus = false;
    this.showIncomeQuestions = false;
    this.foreignIncomeInTaxYear = "Select..."
    this.foreignIncomeInPresentYears = "Select..."
    this.showNewVisitHelpText = false;
    this.showEndDateCaveat = false;
    this.showForm8843Caveat = false;
    this.visits[index] = _.cloneDeep(visit);
    this.checkVisitsForErrors();
    if (!this.visitsHaveErrors) {
      this.showOverlapError = this.aminraService.doVisitsOverlap(this.constructVisits());
    }
  }

  onVisitRemoved(visit, index) {
    this.showUnexpectedErrorMessage = false;
    this.showStatus = false;
    this.showIncomeQuestions = false;
    this.foreignIncomeInTaxYear = "Select..."
    this.foreignIncomeInPresentYears = "Select..."
    this.showNewVisitHelpText = false;
    this.showEndDateCaveat = false;
    this.showForm8843Caveat = false;
    this.visits.splice(index, 1);
    if (this.visits.length === 0) {
      this.addNewVisit();
    }
    this.checkVisitsForErrors();
    if (!this.visitsHaveErrors) {
      this.showOverlapError = this.aminraService.doVisitsOverlap(this.constructVisits());
    }
  }

  trackIndex(index, item) {
    return item.id;
  }

  clearVisits() {
    this.visitsHaveErrors = false;
    this.showOverlapError = false;
    this.showIncomeQuestionsError = false;
    this.showStatus = false;
    this.showIncomeQuestions = false;
    this.foreignIncomeInTaxYear = "Select..."
    this.foreignIncomeInPresentYears = "Select..."
    this.showEndDateCaveat = false;
    this.showForm8843Caveat = false;
    this.visits = [];
    this.showUnexpectedErrorMessage = false;
    this.addNewVisit();
  }

  checkVisitsForErrors() {
    this.visitsHaveErrors = false;
    this.visits.forEach((visit: VisitModel) => {
      if (visit.error && visit.error.length > 0) {
        this.visitsHaveErrors = true;
        return;
      }
    });
  }

  constructVisits() {
    const visits: Visit[] = [];
    this.visits.forEach((visit: VisitModel) => {
      visits.push(new Visit(visit.id, visit.visa, visit.entry, visit.exit, visit.error));
    });
    return visits;
  }

  onSubmit() {
    if (this.canAddNewVisit()) {
      this.showNewVisitHelpText = false;
      if (this.foreignIncomeInTaxYear === "Yes" && this.foreignIncomeInPresentYears === "Yes") {
        this.FIncomeQ = true;
      }
      else {
        this.FIncomeQ = false;
      }
      this.aminraService.setVisits(this.constructVisits());
      this.nonResidentStatus = this.aminraService.isNonresident(this.constructVisits(), this.selectedYear, this.FIncomeQ);
      sessionStorage.nonResidentStatus = this.nonResidentStatus.status;
      sessionStorage.daysInUSTY = this.nonResidentStatus.daysPresentInTaxYear;
      sessionStorage.daysInUSTY1 = this.nonResidentStatus.daysPresentYearPriorToTaxYear;
      sessionStorage.daysInUSTY2 = this.nonResidentStatus.daysPresent2YearsPriorToTaxYear;
      sessionStorage.exemptCount = this.nonResidentStatus.daysExemptInTaxYear;

      if (!this.nonResidentStatus) {
        this.showUnexpectedErrorMessage = true;
      } else if (this.nonResidentStatus.residencyEndDate && this.nonResidentStatus.residencyEndDate.includes('*')) {
        this.showEndDateCaveat = true;
        this.showUnexpectedErrorMessage = false;
      }

      this.showForm8843Caveat = this.nonResidentStatus.exemptAsForJInTaxYear;
      if (this.nonResidentStatus.daysExemptInTaxYear>0) {
        this.showForm8843Caveat = true;
      }

      if (this.nonResidentStatus.exempt3Of6Count <= 3 && this.nonResidentStatus.presentAsTeacherTraineeYears.length>0 && !this.nonResidentStatus.allExemptYears.includes(this.selectedYear)) {
        this.showIncomeQuestions = true;
        this.foreignPayYears = this.nonResidentStatus.presentAsTeacherTraineeYears.reverse().toString().split(",").join(", ");
      }

      if (this.showIncomeQuestions === false) {
        this.showStatus = true;
      }
      else if (this.showIncomeQuestions == true && (this.foreignIncomeInTaxYear === "Select..." || this.foreignIncomeInPresentYears === "Select...")) {
        this.showStatus = false;
        this.showIncomeQuestionsError = true;
      }
      
      else if (this.showIncomeQuestions == true && (!(this.foreignIncomeInTaxYear === "Select...") && !(this.foreignIncomeInPresentYears === "Select..."))) {
        this.showStatus = true;
        this.showIncomeQuestionsError = false;
      }

      else {
          this.showStatus = true;
      }

      if (this.nonResidentStatus.dualStatusResidentText == true) {
        this.showDualStatusResidentText = true;
      }

    }
    
    else {
      this.showStatus = false;
      this.showIncomeQuestions = false;
      this.showNewVisitHelpText = true;
    }
  }

  selectedYearChange() {
    this.showStatus = false;
    this.showIncomeQuestions = false;
  }

  onSubmitGoTonr02() {
      this.router.navigate(['../pg-nr02'], {relativeTo: this.route});
  }

  onSubmitBack() {
      if (this.navFrompg) {
        this.router.navigate(['../pg-nr01'], {relativeTo: this.route});
      }
      else {
        this.router.navigate(['../tools'], {relativeTo: this.route});
      }
  }
  
}
