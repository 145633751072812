import { Component, OnInit } from '@angular/core';
import { statesList } from "./pg-nr06.service"
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pg-nr06',
  templateUrl: './pg-nr06.component.html',
  styleUrls: ['./pg-nr06.component.scss']
})
export class PGnr06Component implements OnInit {

  selectYesNo = ['Yes', 'No']
  selectTaxResidencyStatus = ['Resident', 'Dual Status', 'Nonresident']
  taxYear: any;
  states = statesList;
  stateLived1: string = null;
  stateLived2: string = null;
  stateLived3: string = null;
  stateIncome1: string = null;
  stateIncome2: string = null;
  stateIncome3: string = null;
  errorMismatched: boolean = false;
  errorNoneYes: boolean = false;
  showContinueButton: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.showContinueButton = false;
    this.taxYear = sessionStorage.taxYear;
    sessionStorage.stateLived1 = ''
    sessionStorage.stateLived2 = ''
    sessionStorage.stateLived3 = ''
    sessionStorage.stateIncome1 = ''
    sessionStorage.stateIncome2 = ''
    sessionStorage.stateIncome3 = ''
    this.errorMismatched = false;
    this.errorNoneYes = false;
  }

 entriesChange() {
    if ( (this.stateLived1 != null   || this.stateLived2 != null   || this.stateLived3 != null  ) &&
         (this.stateIncome1 == 'Yes' || this.stateIncome2 == 'Yes' || this.stateIncome3 == 'Yes')) {
      this.showContinueButton = true;
    }
    else {
      this.showContinueButton = false;
    }
 }

  clearEntries() {
    this.stateLived1 = null
    this.stateLived2 = null
    this.stateLived3 = null
    this.stateIncome1 = null
    this.stateIncome2 = null
    this.stateIncome3 = null
    sessionStorage.stateLived1 = ''
    sessionStorage.stateLived2 = ''
    sessionStorage.stateLived3 = ''
    sessionStorage.stateIncome1 = ''
    sessionStorage.stateIncome2 = ''
    sessionStorage.stateIncome3 = ''
    this.showContinueButton = false
    this.errorMismatched = false;
  }

  onSubmitGoToNextPage() {
    if ( (this.stateLived1 == null && this.stateIncome1 != null) || (this.stateLived1 != null && this.stateIncome1 == null) ||
         (this.stateLived2 == null && this.stateIncome2 != null) || (this.stateLived2 != null && this.stateIncome2 == null) ||
         (this.stateLived3 == null && this.stateIncome3 != null) || (this.stateLived3 != null && this.stateIncome3 == null) ) {
      this.errorMismatched = true;
      return
    }

    sessionStorage.stateLived1 = this.stateLived1;
    sessionStorage.stateLived2 = this.stateLived2 ?? '';
    sessionStorage.stateLived3 = this.stateLived3 ?? '';
    sessionStorage.stateIncome1 = this.stateIncome1;
    sessionStorage.stateIncome2 = this.stateIncome2 ?? '';
    sessionStorage.stateIncome3 = this.stateIncome3 ?? '';
    sessionStorage.navDir = 'forward'
    sessionStorage.prevPage = 'pg-nr06'
    
    if (this.stateLived1 == "Minnesota" || this.stateLived2 == "Minnesota" || this.stateLived3 == "Minnesota" ) {
      this.router.navigate(['../pg-nr07'], {relativeTo: this.route});
    }
    else {
      this.router.navigate(['../pg-nr08'], {relativeTo: this.route});
    }
  }

  onSubmitBack() {
    sessionStorage.navDir = 'backward'
    //sessionStorage.prevPage = 'pg-nr06'
    this.router.navigate(['../pg-nr05'], {relativeTo: this.route});
  }

}
