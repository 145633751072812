import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pg-nr07',
  templateUrl: './pg-nr07.component.html',
  styleUrls: ['./pg-nr07.component.scss']
})
export class PGnr07Component implements OnInit {

  certificateAnswer: string
  showFrontlineWorkerQ: Boolean
  frontlineWorkerAnswer: string
  showPayPropTaxQ: Boolean
  payPropTaxAnswer: string
  showContinueButton: boolean
  taxYear: any
  selectYesNo=['Yes','No']

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.certificateAnswer = null;
    this.frontlineWorkerAnswer = null;
    this.showPayPropTaxQ = false;
    this.showFrontlineWorkerQ = false;
    this.showContinueButton = false;
    sessionStorage.certificateRentPaid = '';
    this.taxYear = sessionStorage.taxYear;
  }

  certificateChange() {
      this.showPayPropTaxQ = true;
      this.showFrontlineWorkerQ = false;
      this.showContinueButton = false;
  }

  payPropTaxChange() {
    this.showPayPropTaxQ = true;
    this.showFrontlineWorkerQ = true;
    this.showContinueButton = false;   
  }

  frontlineWorkerChange() {
    this.showContinueButton = true;   
  }

  onSubmitGoToNextPage() {
    sessionStorage.certificateRentPaid = this.certificateAnswer;
    sessionStorage.payPropTax = this.payPropTaxAnswer;
    sessionStorage.frontlineWorker = this.frontlineWorkerAnswer;
    sessionStorage.navDir = 'forward'
    sessionStorage.prevPage = 'pg-nr07'
    this.router.navigate(['../pg-nr08'], {relativeTo: this.route});
  }

  onSubmitBack() {
    sessionStorage.navDir = 'backward'
    sessionStorage.prevPage = 'pg-nr07'
    this.router.navigate(['../pg-nr06'], {relativeTo: this.route});
  }

}
