import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import * as _ from 'lodash';
import { DocModel } from './docs/DocsModel';
import { tbService, countryindexes, resStatuses, selectableVisaCodes, binChoices, type_16_19_div_cap, type_16_20_div_cap, type_div_cap, TB_WagesSVisaCodes, TB_WagesNSVisaCodes, TB_ScholarshipsSVisaCodes } from './tb.service';
//import { Visa } from './docs/Visa';
import { ctbdata, ctbdataCountryOnly } from './docs/TBData';
import { Doc } from './docs/Docs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tb',
  templateUrl: './tb.component.html',
  styleUrls: ['./tb.component.scss']
})

export class tbComponent implements OnInit {
  // Variables for controlling whether messages are shown to the user, error messages or status
  navFrompg: boolean = false;
  showNewDocHelpText = false;
  showOverlapError = false;
  docsHaveErrors = false;
  emptydoc = false;
  crossedincome = false;
  showStatus = false;
  scenario = 0;

  // Stage1Failstates
  s11 = false;
  s12 = false;
  s13 = false;
  s14 = false;
  s15 = false;
  s1f = true;

  // Variables controlling state of webpage
  showBenefits = false;
  documententry = false;
  showDocMatches = false;
  showNextPageContinueButton: boolean = false;

  // Variables holding information
  docs: DocModel[] = [];
  TB_Entries: any[] = [];

  // Choosable Variables
  selectableVisas = selectableVisaCodes;
  selectedVisa = null;
  countriescovered = countryindexes;
  selectedCountry = null;
  residentialStatuses = resStatuses;
  selectedResidency = "Nonresident Alien";
  binaryagree = binChoices;
  capitalgains: string = "No";
  dividends: string = "No";

  //Activated benefits
  bc16 = false;
  bc19 = false;
  bc20 = false;
  dividendaccess = false;
  capitalgainaccess = false;
  // Variables which page branch is show
  // These are NOT the same as above
  // You can be type 19 with bc19 disabled
  // It just makes sure you don't get disabled messages for another wage type
  type19 = false;
  type20 = false;
  // Default table
  cdata = ctbdata[0];
  countryIndex: number = null;
  tbWage2018: string = null;

  // Variables for final document matching
  scholarshiptreaty = null;
  scholarshipamount = null;
  scholarshipmatch = false;
  scholarshipvalues = [];

  wagetreaty = null;
  amountWageTotal = null;
  wagematch = false;
  wagevalues = [];

  //Document tracking data
  s1042_w_count = 0; //Number of 1042-S Scholarship Entries
  s1042_s_count = 0; //Number of 1042-S Wage Entries
  w2_count = 0;     //Number of W-2 Entries
  fellow_count = 0;  //Number of Fellowship Entries

  //taxslayerdata scholarship then wages
  stsvalues = [0, 0, false, null, null]
  wtsvalues = [0, 0, false, null, null]

  // Used to give each added doc a unique id
  docCounter = 0;
  showUnexpectedErrorMessage: boolean;

  constructor(
    private tbService: tbService,
    private changeDetection: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,

    ) { }
    
  trackIndex(index, item) {
    return item.id;
  }
  ngOnInit() {
    this.addNewDoc();
    this.tbWage2018 = '20'
    if (sessionStorage.pg == 'pgTrue') {
      sessionStorage.TB = 'Yes'
      this.navFrompg = true;
      this.selectedVisa = sessionStorage.visaType
      this.selectedCountry = sessionStorage.taxResidentCountry
      this.selectedResidency = sessionStorage.nonResidentStatus

      this.countryIndex = ctbdataCountryOnly.indexOf(sessionStorage.taxResidentCountry)
      
      if ( (sessionStorage.income1042S16 == 'Yes' || sessionStorage.incomeScholarship == 'Yes') && TB_ScholarshipsSVisaCodes.includes(this.selectedVisa) && ctbdata[this.countryIndex][1][0] != null) {sessionStorage.TB_Scholarship = 'Yes'}
      else {sessionStorage.TB_Scholarship = 'No'}

      if ( ( (sessionStorage.income1042S20 == 'Yes' || sessionStorage.incomeW2 == 'Yes' ) &&  TB_WagesSVisaCodes.includes(this.selectedVisa) && ctbdata[this.countryIndex][3][0] != null)
        || ( (sessionStorage.income1042S19 == 'Yes' || sessionStorage.incomeW2 == 'Yes' ) && TB_WagesNSVisaCodes.includes(this.selectedVisa) && ctbdata[this.countryIndex][2][0] != null) )   
       { sessionStorage.TB_Wages = 'Yes'}
      else {sessionStorage.TB_Wages = 'No'}

      if (sessionStorage.income1099B == "Yes") {
        this.capitalgains = "Yes"
      }
      if (sessionStorage.income1099DIV == "Yes" || sessionStorage.income1042SDividends == "Yes" ) {
        this.dividends = "Yes"
      }
    }

    else {
      this.navFrompg = false;
    }
    sessionStorage.navFromTB = "Yes";
    sessionStorage.navFromPGnr04 = "No";
    this.TB_Entries = [];
  }

  benefittables(country, visacode) {
    this.cdata = this.grabBenefits(country);
    if (type_16_19_div_cap.includes(visacode)) {
      //this.datasetter(1);
      this.bc16 = true;
      this.bc19 = true;
      this.bc20 = false;
      if (this.cdata[1][0] === null) {
        this.bc16 = false;
      }
      if (this.cdata[2][0] === null) {
        this.bc19 = false;
      }
      this.type19 = true;
      this.type20 = false;
    }
    else if (type_16_20_div_cap.includes(visacode)) {
      //this.datasetter(1);
      this.bc16 = true;
      this.bc19 = false;
      this.bc20 = true;
      if (this.cdata[1][0] === null) {
        this.bc16 = false;
      }
      if (this.cdata[3][0] === null) {
        this.bc20 = false;
      }
      this.type19 = false;
      this.type20 = true;
    }
    else if (type_div_cap.includes(visacode)) {
      //this.datasetter(1);
      this.bc16 = false;
      this.bc19 = false;
      this.bc20 = false;
      this.type19 = false;
      this.type20 = false;
      if (this.selectedCountry == "Canada") {
        if (this.selectedVisa == "F-2 Student" ||
            this.selectedVisa == "J-2 Student" ||
            this.selectedVisa == "Q-2 Student"
        ) { 
          this.bc19 = true; 
          this.type19 = true;
        }
        else if (this.selectedVisa == "J-2 Non-Student") { 
          this.bc20 = true; 
          this.type20 = true;
        }

      }
    }
    //The indian scenario
    if (this.selectedCountry == "India" && (
         this.selectedVisa == "F-1 Student"
      || this.selectedVisa == "J-1 Student"
      || this.selectedVisa == "M-1 Student"
      || this.selectedVisa == "Q-1 Student"
    )) {
      this.scenario = 1;
    }
    else if (!this.bc16 && !this.bc19 && !this.bc20) {
      this.scenario = 3;
    }
    else {
      this.scenario = 2;
    }

    if (this.selectedResidency === "Resident Alien") {
      if (this.cdata[11][0] && this.bc16) {
        this.bc16 = true;
      }
      else {
        this.bc16 = false;
      }

      if (this.cdata[11][1] && this.bc19) {
        this.bc19 = true;
      }
      else {
        this.bc19 = false;
      }
      if (this.cdata[11][2] && this.bc20) {
        this.bc20 = true;
      }
      else {
        this.bc20 = false;
      }

    }

    if (this.capitalgains === "Yes") {
      this.capitalgainaccess = true;
    }
    else {
      this.capitalgainaccess = false;
    }

    if (this.dividends === "Yes") {
      this.dividendaccess = true;
    }
    else {
      this.dividendaccess = false;
    }
    //Canada Scenario
    if (this.selectedCountry == "Canada") {
      this.tbWage2018 = '18'
    }

  }

  addNewDoc() {
    this.showUnexpectedErrorMessage = false;
    if (this.canAddNewDoc()) {
      this.showNewDocHelpText = false;
      this.docs.push({
        doctype: null,
        incomecode: null,
        incomeval: 0,
        id: this.docCounter++
      });
    } else {
      this.showNewDocHelpText = true;
    }
  }

  canAddNewDoc() {
    let numberOfEmptyExitDates = 0;
    for (const doc of this.docs) {
    }
    return true;
  }

  onDocChanged(doc, index) {
    this.showDocMatches = false;


    this.showUnexpectedErrorMessage = false;
    this.showStatus = false;
    this.showNewDocHelpText = false;
    this.docs[index] = _.cloneDeep(doc);
    this.checkDocsForErrors();
  }

  onDocRemoved(doc, index) {
    this.showUnexpectedErrorMessage = false;this.docs
    this.showStatus = false;
    this.showNewDocHelpText = false;
    this.docs.splice(index, 1);
    if (this.docs.length === 0) {
      this.addNewDoc();
    }
    this.checkDocsForErrors();
  }


  grabBenefits(item) {

    for (var val in ctbdata) {
      if (item === ctbdata[val][0]) {
        return ctbdata[val];
      }
    }
    return ctbdata[0];
  }

  clearDocs() {
    this.docsHaveErrors = false;
    this.emptydoc = false;
    this.crossedincome = false;
    this.showOverlapError = false;
    this.showStatus = false;
    this.docs = [];
    this.showUnexpectedErrorMessage = false;
    this.showDocMatches = false;
    this.addNewDoc();
  }

  checkDocsForErrors() {
    this.docsHaveErrors = false;
    this.crossedincome = false;
    this.emptydoc = false;
    this.docs.forEach((doc: DocModel) => {
      if (doc.doctype == null) {
        this.emptydoc = true;
        this.docsHaveErrors = true;

      }

      if (doc.doctype == "1042-S") {
        if (this.type19 && doc.incomecode == "20") {
          this.crossedincome = true;
          this.docsHaveErrors = true;

        }
        if (this.type20 && doc.incomecode == "19") {
          this.crossedincome = true;
          this.docsHaveErrors = true;

        }

      };
      if (doc.error.length > 0) {
        this.docsHaveErrors = true;
        return;
      }
    });
  }

  constructDocs() {
    const docs: Doc[] = [];
    this.docs.forEach((doc: DocModel) => {
      docs.push(new Doc(doc.id, doc.doctype, doc.incomecode, doc.incomeval, doc.error));
    });
    return docs;
  }

  onSubmit() {
    if (this.canAddNewDoc()) {
      this.showNewDocHelpText = false;
      this.showStatus = true;
    } else {
      this.showStatus = false;
      this.showNewDocHelpText = true;
    }
  }

  onSubmitGoToNextPage() {
    sessionStorage.navDir = 'forward'
    this.router.navigate(['../pg-nr05'], {relativeTo: this.route});
    }

  onSubmitBack() {
    sessionStorage.navDir = 'backward'
    if (!this.navFrompg) {
      this.router.navigate(['..'], {relativeTo: this.route});
    }
    else {
      this.router.navigate(['../pg-nr04'], {relativeTo: this.route}); 
    }
  }

  // Checks for wrong input in to level entry
  stage1checker() {
    this.s11 = false;
    this.s12 = false;
    this.s13 = false;
    this.s14 = false;
    this.s15 = false;

    if (this.selectedVisa == null) {
      this.s11 = true;
    }

    if (this.selectedCountry == null) {
      this.s12 = true;
    }

    if (this.selectedResidency == null) {
      this.s13 = true;
    }

    if (this.capitalgains == null) {
      this.s14 = true;
    }

    if (this.dividends == null) {
      this.s15 = true;
    }

    if (this.s11 || this.s12 || this.s13 || this.s14 || this.s15) {
      this.s1f = false;
    } else {
      this.s1f = true;
    }
  }

  checkBenefits() {
    this.stage1checker();
    this.showBenefits = true;
    this.benefittables(this.selectedCountry, this.selectedVisa);
    if (this.bc16 === true || this.bc19 === true || this.bc20 === true) {
      this.documententry = true;
      this.showBenefits = true;
    }
    else {
      this.documententry = false;

    }
    this.showBenefits = true;
    if (this.navFrompg) {
      this.checkMatches()
      this.onSubmitGoToNextPage()
    }
  }

  clearBenefits() {
    this.selectedVisa = null;
    this.selectedCountry = null;
    this.capitalgains = "No";
    this.dividends = "No";
    this.bc16 = false;
    this.bc19 = false;
    this.bc20 = false;
    this.showBenefits = false;
    this.showDocMatches = false;
  }

  docDatareset() {
    this.showDocMatches = false;
    // Variables for final document matching
    this.scholarshiptreaty = null;
    this.scholarshipamount = null;
    this.scholarshipmatch = false;

    this.wagetreaty = null;
    this.amountWageTotal = null;
    this.wagematch = false;
    //taxslayerdata scholarship then wages
    this.stsvalues = [0, 0, false, null, null]
    this.wtsvalues = [0, 0, false, null, null]

  }

  checkMatches() {
    this.docDatareset();
    this.checkDocsForErrors();
    if (this.docsHaveErrors) { return };
    //reinitialize values
    var w2_count = 0;
    var c_16_1042_count = 0;
    var c_19_1042_count = 0;
    var c_20_1042_count = 0;
    var fellow_count = 0;
    var amountw2 = 0;
    var amount1042s_16 = 0;
    var amount1042s_19_20 = 0;
    var amountfellow = 0;
    this.w2_count = 0;      //Number of W-2 Entries
    this.s1042_w_count = 0; //Number of 1042-S Wage Entries
    this.s1042_s_count = 0; //Number of 1042-S Scholarship Entries
    this.fellow_count = 0;  //Number of Fellowship Entries

    this.wtsvalues = [0, 0, false, null, null]
    this.stsvalues = [0, 0, false, null, null]
    this.scholarshipvalues = [];
    this.wagevalues = [];

    this.showDocMatches = true;
    this.scholarshipamount = 0;
    this.amountWageTotal = 0;
    this.stsvalues[4] = 100;
    
    //Aggregate data
    this.docs.forEach((element: DocModel) => {
      
      if (element.doctype == "1042-S") {
        if (element.incomecode == "16") {
          c_16_1042_count += 1;
          this.s1042_s_count += 1;
          this.scholarshipamount += element.incomeval;
          amount1042s_16 += element.incomeval;
          this.scholarshipvalues.push([element.doctype, element.incomeval]);
        }
        else if (element.incomecode == "19") {
          c_19_1042_count += 1;
          this.s1042_w_count += 1;
          this.amountWageTotal += element.incomeval;
          amount1042s_19_20 += element.incomeval;
          this.wagevalues.push([element.doctype, element.incomeval]);
        }
        else if (element.incomecode == "20") {
          c_20_1042_count += 1;
          this.s1042_w_count += 1;
          this.amountWageTotal += element.incomeval;
          amount1042s_19_20 += element.incomeval;
          this.wagevalues.push([element.doctype, element.incomeval]);
        }
      }

      else if (element.doctype == "W-2") {
        this.w2_count += 1;
        this.amountWageTotal += element.incomeval;
        amountw2 += element.incomeval;
        this.wagevalues.push([element.doctype, element.incomeval]);
      }
      
      else if (element.doctype == "Fellowship") {
        this.fellow_count += 1;
        amountfellow += element.incomeval;
        this.scholarshipamount += element.incomeval;
        this.scholarshipvalues.push([element.doctype, element.incomeval]);
      }

    });

    this.stsvalues[4] = 130;

    if (this.bc16) {
      this.scholarshiptreaty = this.cdata[1];
    }
    if (this.bc19) {
      this.wagetreaty = this.cdata[2];
    }
    else if (this.bc20) {
      this.wagetreaty = this.cdata[3];
    }
    this.stsvalues[4] = 140;

    // No limit wages
    this.wtsvalues[4] = 100;

    if (this.bc19 || this.bc20) {
      if (this.wagetreaty[1] == "No Limit") {
        this.wtsvalues[4] = 110;
        
        // Yes 1042 No W2 
        if (this.w2_count == 0 && this.s1042_w_count > 0) {
          this.wtsvalues = [0, 0, false, null, 0];
          this.wagematch = true;

        }
        // No 1042 No W2
        else if (this.w2_count == 0 && this.s1042_w_count == 0) {
          this.wtsvalues = [0, 0, false, null, 1];
          this.wagematch = true;
        }
        
        // Yes 1042 Yes W2
        else if (this.w2_count > 0 && this.s1042_w_count > 0) {
          this.wtsvalues[0] = amountw2;
          this.wtsvalues[1] = amountw2 + amount1042s_19_20;
          this.wtsvalues[2] = false;
          this.wtsvalues[4] = 2;
          this.wagematch = false;
        }
        
        // No 1042 Yes W2
        else if (this.w2_count > 0 && this.s1042_w_count == 0) {
          this.wtsvalues[0] = amountw2;
          this.wtsvalues[1] = amountw2 + amount1042s_19_20;
          this.wtsvalues[2] = false;
          this.wtsvalues[4] = 3;
          this.wagematch = false;
        }
      }


//////// TREATY LIMITS

  /////// 1042S WAGES > TB

      // 1042s_19or20 > TB - 1042S wages greater than Treaty Benefit
//////else if (amount1042s_19_20 > this.wagetreaty[1]) {
      else if (amount1042s_19_20 > this.wagetreaty[1]) {
        this.wtsvalues[4] = 120;
        
        // Yes 1042, No W2 - Wages from 1042S only and are > TB
        if (this.s1042_w_count > 0 && this.w2_count == 0) {
          this.wtsvalues[0] = this.wagetreaty[1] - amount1042s_19_20;
          this.wtsvalues[1] = this.wagetreaty[1];
          this.wtsvalues[2] = false;
          this.wtsvalues[4] = 4;
          this.wagematch = false;
        }
        
        // Yes 1042, Yes W2 - Wages from both 1042S & W2, and 1042S wages are > TB
        // Same as case above with no W2
        else if (this.s1042_w_count > 0 && this.w2_count > 0) {
          this.wtsvalues[0] = this.wagetreaty[1] - amount1042s_19_20;
          this.wtsvalues[1] = this.wagetreaty[1];
          this.wtsvalues[2] = false;
          this.wtsvalues[4] = 5;
          this.wagematch = false;
        }

/*
        //NEWLY ADDED  - DONT NEED ANY MORE?
        // No 1042, Yes W2 - Wages from W2 only and are > TB
        else if (this.s1042_w_count = 0 && this.w2_count > 0) {
          this.wtsvalues[0] = this.wagetreaty[1];
          this.wtsvalues[1] = this.wagetreaty[1];
          this.wtsvalues[2] = false;
          this.wtsvalues[4] = 5;
          this.wagematch = false;
        }
*/
      }

  ////// 1042S WAGES <= TB

      // 1042s_19or20 <= TB
      // amountWageTotal <= TB - All wages <= TB
//////else if (amount1042s_19_20 <= this.wagetreaty[1]) {
      else if (amount1042s_19_20 <= this.wagetreaty[1]) {
        this.wtsvalues[4] = 130;
        
        // Yes 1042, No W2 - All wages are from 1042S and are <= TB   => Benefits Match
        if (this.s1042_w_count > 0 && this.w2_count == 0) {
          this.wtsvalues = [0, 0, false, null, null];
          this.wtsvalues[4] = 66;
          this.wagematch = true;
        }


//////////Dont't think this is needed
        // 1042s_19or20 = TB, No W2 - All wages are from 1042s and are = TB
        else if (amount1042s_19_20 == this.wagetreaty[1]) {
          this.wtsvalues = [0, 0, false, null, null];
          this.wtsvalues[4] = 6;
          this.wagematch = true;
        }


        // Yes 1042S, Yes W2 - Wages from 1042S and W2, and 1042S <= TB
        else if (this.s1042_w_count > 0 && this.w2_count > 0) {
          this.wtsvalues[0] = Math.min(this.wagetreaty[1] - amount1042s_19_20, amountw2);
          this.wtsvalues[1] = Math.min(this.wagetreaty[1], this.amountWageTotal);
          this.wtsvalues[2] = false;
          this.wtsvalues[4] = 7;
          this.wagematch = false;
        }
       
        // W-2 and 1042-S
        else if (this.s1042_w_count > 0 && this.w2_count > 0) {
          this.wtsvalues[0] = this.wagetreaty[1] - amountw2;
          //this.wtsvalues[0] = amountw2; //this.wagetreaty[1] - amount1042s_19_20;
          this.wtsvalues[1] = this.amountWageTotal; //this.wagetreaty[1];
          this.wtsvalues[2] = false;
          this.wtsvalues[4] = 8;
          this.wagematch = false;
        }
        
        // 
        else if (this.s1042_w_count == 0 && this.w2_count > 0) {
          this.wtsvalues[0] = Math.min(this.wagetreaty[1], amountw2);
          this.wtsvalues[1] = Math.min(this.wagetreaty[1], amountw2);
          this.wtsvalues[2] = false;
          this.wtsvalues[4] = 9;
          this.wagematch = false;
        }
      }
    }

    // the canadian scenario
    if (this.selectedCountry == "Canada" && this.amountWageTotal > 10000) {
      if (this.s1042_w_count > 0) {
        this.scenario = 3;
        this.wtsvalues[0] = 0 - amount1042s_19_20;
        this.wtsvalues[1] = Math.min(this.wagetreaty[1], this.amountWageTotal);
        this.wtsvalues[2] = true;
        this.wtsvalues[4] = 80;
      }
      else {
        this.scenario = 3;
        this.s1042_w_count = 0;
        this.w2_count = 0;
        this.wtsvalues[4] = 81;
      }
    }
    else if (this.selectedCountry == "Canada" && this.amountWageTotal <= 10000) {
      if (this.s1042_w_count > 0) {  //This was a < sign originally (changed on 4/12/23)
        this.scenario = 2;
        this.wtsvalues[0] = amountw2;
        this.wtsvalues[1] = amountw2;
        this.wtsvalues[4] = 82;
        this.wagematch = true;
      }
      else {
        this.scenario = 2;
        this.wtsvalues[0] = amountw2;
        this.wtsvalues[1] = amountw2;
        this.wtsvalues[4] = 83;
        this.wagematch = false;
      }
    }

    // No limit Scholarship
    //    this.wtsvalues[4] = 200;
    this.stsvalues[4] = 200;

    if (this.bc16) {

      if (this.scholarshiptreaty[1] == "No Limit") {
        // Yes 1040 No Fellow
        this.stsvalues[4] = 300;
        if (this.fellow_count == 0 && this.s1042_s_count > 0) {
          this.stsvalues = [0, 0, false, null, null,]
          this.stsvalues[4] = 0;
          this.scholarshipmatch = true;

        }
        // No 1040 No fellow
        else if (this.fellow_count == 0 && this.s1042_s_count == 0) {
          this.stsvalues[4] = 1;
          this.scholarshipmatch = true;
        }
        // Yes 1040 Yes Fellow
        else if (this.fellow_count > 0 && this.s1042_s_count > 0) {
          this.stsvalues[1] = amount1042s_16 + amountfellow;
          this.stsvalues[2] = false;
          this.stsvalues[4] = 2;
          this.scholarshipmatch = false;
        }
        // No 1040 Yes Fellow
        else if (this.fellow_count > 0 && this.s1042_s_count == 0) {
          this.stsvalues[1] = this.scholarshipamount;
          this.stsvalues[2] = false;
          this.stsvalues[4] = 3;
          this.scholarshipmatch = false;
        }
      }

      // Pure 1042 scholarship >= TB
      else if (amount1042s_16 >= this.scholarshiptreaty[1]) {
        // Yes 1042 No overflow No fellow
        this.stsvalues[4] = 400;
        if (this.fellow_count == 0 && amount1042s_16 == this.scholarshiptreaty[1]) {
          this.stsvalues[4] = 4;
          this.scholarshipmatch = true;
        }
        // Yes 1042 Yes Fellow No overflow
        else if (this.fellow_count > 0 && amount1042s_16 == this.scholarshiptreaty[1]) {
          this.stsvalues[0] = this.scholarshiptreaty[1] - amount1042s_16;
          this.stsvalues[1] = this.scholarshiptreaty[1];
          this.stsvalues[2] = false;
          this.stsvalues[4] = 5;
          this.scholarshipmatch = true;
        }
        // Yes 1042 No Fellow yes overflow
        else if (this.fellow_count == 0 && this.s1042_s_count > 0) {
          this.stsvalues[0] = this.scholarshiptreaty[1] - amount1042s_16;
          this.stsvalues[1] = this.scholarshiptreaty[1];
          this.stsvalues[2] = false;
          this.stsvalues[4] = 6;
          this.scholarshipmatch = false;
        }
        // Yes 1042 yes Fellow yes overflow
        else if (this.fellow_count > 0 && this.s1042_s_count > 0) {
          this.stsvalues[0] = this.scholarshiptreaty[1] - (amount1042s_16 + amountfellow);
          this.stsvalues[1] = this.scholarshiptreaty[1];
          this.stsvalues[2] = false;
          this.stsvalues[4] = 7;
          this.scholarshipmatch = false;
        }
      }

      // Pure 1042 scholarship = TB 
      else if (amount1042s_16 == this.scholarshiptreaty[1]) {
        this.stsvalues[4] = 500;
        if (this.fellow_count == 0) {
          this.stsvalues[4] = 10;
          this.scholarshipmatch = true;
        }
        else {
          this.stsvalues[0] = amount1042s_16 - this.scholarshiptreaty[1];
          this.stsvalues[1] = Math.min(this.scholarshiptreaty[1], amount1042s_16 + amountfellow);
          this.stsvalues[2] = false;
          this.stsvalues[4] = 9;
          this.scholarshipmatch = false;
        }

      }

      // Pure 1042 < TB 
      else if (amount1042s_16 < this.scholarshiptreaty[1]) {
        this.stsvalues[0] = this.scholarshiptreaty[1] //- amount1042s_16;
        this.stsvalues[1] = Math.min(this.scholarshiptreaty[1], amount1042s_16 + amountfellow);
        this.stsvalues[2] = false;
        this.stsvalues[4] = 8;
        this.scholarshipmatch = false; // CHANGED 2/19/2022 TO MAKE SchFell10 work
      }
    }
    this.stsvalues[3] = this.s1042_s_count + this.fellow_count;
    this.wtsvalues[3] = this.s1042_w_count + this.w2_count;

    // if (this.scholarshipmatch) {
    //   this.stsvalues[3] = this.s1042_s_count + this.fellow_count;
    // }
    // else {
    //   this.stsvalues[3] = this.s1042_s_count + this.fellow_count;



    // }
    // if (this.wagematch) {
    //   this.wtsvalues[3] = this.s1042_w_count + this.w2_count;
    // }
    // else {
    //   this.wtsvalues[3] = this.s1042_w_count + this.w2_count;
    // }

    if (this.wagematch) { sessionStorage.TB_MatchStatusWages = "Yes" } else {sessionStorage.TB_MatchStatusWages = "No" }
    if (this.scholarshipmatch) { sessionStorage.TB_MatchStatusScholarship = "Yes" } else {sessionStorage.TB_MatchStatusScholarship = "No" }

    if (this.navFrompg) {

      //Match Status Wages
      if (this.bc20 || this.bc19) {
        
        //if ( (amount1042s_19_20 <= this.wagetreaty[1]  || this.wagetreaty[1] == 'No Limit') && (this.w2_count == 0 || amountw2 == 0)) {
        //  sessionStorage.TB_MatchStatusWages = "Yes"   
        //}
        //else {
        //  sessionStorage.TB_MatchStatusWages = "No"
        //}

        var textBlock_MatchStatusWagesSec1 = []
        var textBlock_MatchStatusWagesSec2 = []
        var textBlock_MatchStatusWagesSec3 = []

        //if (this.s1042_w_count > 0 || this.w2_count > 0) {
 
          //this.wagevalues.forEach((docEntry, index) => { textBlock_MatchStatusWages.push(this.wagevalues[index][0] + ": " + this.wagevalues[index][1]) })
          //textBlock_MatchStatusWages.push("Total: " + this.amountWageTotal, "")

          textBlock_MatchStatusWagesSec1.push(
            "Tax residency country: " + sessionStorage.taxResidentCountry,
            "Treaty Article: " + this.wagetreaty[2],
            "Maximum Dollar Amount: " + this.wagetreaty[1]
            )

          this.wagevalues.forEach((docEntry, index) => {
            textBlock_MatchStatusWagesSec2.push(this.wagevalues[index][0], this.wagevalues[index][1])
          })
          if (this.wagevalues.length == 0) {
            textBlock_MatchStatusWagesSec2.push("No entries", "")
          }
          else {
            textBlock_MatchStatusWagesSec2.push("Total")
            textBlock_MatchStatusWagesSec2.push(this.amountWageTotal)
          }
        //}
        
        if (this.wagematch && (this.s1042_w_count > 0 || this.w2_count > 0)) {}
        else if (this.s1042_w_count > 0 || this.w2_count > 0) {
          textBlock_MatchStatusWagesSec3.push("Schedule OI - General Information, Wages exempt by a treaty", this.wtsvalues[0])
          if (!this.wtsvalues[2]) { textBlock_MatchStatusWagesSec3.push("Schedule OI - Income exempt from Tax, Amount of exempt income", this.wtsvalues[1]) } 
        }

        sessionStorage.setItem('TB_MatchStatusWagesSec1', JSON.stringify(textBlock_MatchStatusWagesSec1))
        sessionStorage.setItem('TB_MatchStatusWagesSec2', JSON.stringify(textBlock_MatchStatusWagesSec2))
        sessionStorage.setItem('TB_MatchStatusWagesSec3', JSON.stringify(textBlock_MatchStatusWagesSec3))
        
      }

      //Match Status Fellowship
      if (this.bc16) {

        //if ( (amount1042s_16 <= this.scholarshiptreaty[1] || this.scholarshiptreaty[1] == 'No Limit') && (this.fellow_count == 0 || this.scholarshipamount == 0)) {
        //  sessionStorage.TB_MatchStatusScholarship = "Yes"   
        //}
        //else {
        //  sessionStorage.TB_MatchStatusScholarship = "No"
        //}

        var textBlock_MatchStatusScholarshipSec1 = []
        var textBlock_MatchStatusScholarshipSec2 = []
        var textBlock_MatchStatusScholarshipSec3 = []

        //if (this.s1042_s_count > 0 || this.fellow_count > 0) {
          textBlock_MatchStatusScholarshipSec1.push(
            "Tax residency country: " + sessionStorage.taxResidentCountry,
            "Treaty Article: " +  this.scholarshiptreaty[2],
            "Maximum Dollar Amount: " + this.scholarshiptreaty[1]
          )
          
          this.scholarshipvalues.forEach((docEntry, index) => {
            textBlock_MatchStatusScholarshipSec2.push(this.scholarshipvalues[index][0], this.scholarshipvalues[index][1])
          })
          if (this.scholarshipvalues.length == 0) {
            textBlock_MatchStatusScholarshipSec2.push("No entries", "")
          }
          else {
            textBlock_MatchStatusScholarshipSec2.push("Total")
            textBlock_MatchStatusScholarshipSec2.push(this.scholarshipamount)
          }
        //}

        if (this.scholarshipmatch && (this.s1042_s_count > 0 || this.fellow_count > 0)) {}
        else if (this.s1042_s_count > 0 || this.fellow_count > 0) {
          if (this.stsvalues[2]) { textBlock_MatchStatusScholarshipSec3.push("Schedule OI - General Information, Wages exempt by a treaty", this.stsvalues[0]) }
          textBlock_MatchStatusScholarshipSec3.push("Schedule OI - Income exempt from Tax, Amount of exempt income", this.stsvalues[1])
        }

        sessionStorage.setItem('TB_MatchStatusScholarshipSec1', JSON.stringify(textBlock_MatchStatusScholarshipSec1))
        sessionStorage.setItem('TB_MatchStatusScholarshipSec2', JSON.stringify(textBlock_MatchStatusScholarshipSec2))
        sessionStorage.setItem('TB_MatchStatusScholarshipSec3', JSON.stringify(textBlock_MatchStatusScholarshipSec3))
      
      }


      //if (textBlock_MatchStatusScholarshipSec1.length == 0) {
      //  sessionStorage.TB_MatchStatusScholarship = 'No'
      //}


      //DIVIDEND SECTION
      if (this.dividends == "Yes") {

        if (this.cdata[5][1] == 'none' || this.cdata[5][0] == null) {
          sessionStorage.TB_Dividend = 'No'
        }
        else {
          sessionStorage.TB_Dividend = 'Yes'
          sessionStorage.TB_IncomeDIVTreatyRate = String(this.cdata[5][0])
          var textBlock_DividendTable = []
          var textBlock_DividendText = []
          var textBlock_DividendSpecialNotes = []
          
          textBlock_DividendTable.push(this.cdata[5][0], this.cdata[5][1])
          textBlock_DividendText.push(
            "Generally, dividend income from investments in U.S.corporate stock is considered FDAP (Fixed, Determinable, Annual or Periodic) income, NOT effectively ",
            "connected to the taxpayer's U.S. trade or business and is therefore taxable at a 30% rate on Form 1040-NR, Schedule NEC, NOT on the front income section ",
            "of the form.",
            "",
            "You must review all paragraphs of the treaty article to fully ensure that all conditions are met. You can access the list of tax treaties here: ",
            "https://www.irs.gov/businesses/international-businesses/united-states-income-tax-treaties-a-to-z",
            ""
          )

          let i = 0
          if (ctbdata[this.countryIndex][10].length > 0) {
            while (i < ctbdata[this.countryIndex][10].length) {
              textBlock_DividendSpecialNotes.push(ctbdata[this.countryIndex][10][i])
              textBlock_DividendSpecialNotes.push("")
              i += 1
            }
          }
        }

      }

      sessionStorage.setItem('TB_DividendTable', JSON.stringify(textBlock_DividendTable))
      sessionStorage.setItem('TB_DividendText', JSON.stringify(textBlock_DividendText))
      sessionStorage.setItem('TB_DividendSpecialNotes', JSON.stringify(textBlock_DividendSpecialNotes))


      //CAPITAL GAIN SECTION
      if (this.capitalgains == "Yes") {
      
        if (this.cdata[4][1] == 'None' || this.cdata[4][0] == null) {
          sessionStorage.TB_CapGain = 'No'
        }
        else {
          sessionStorage.TB_CapGain = 'Yes'
          sessionStorage.TB_IncomeCapGainTreatyRate = String(this.cdata[4][0])

          var textBlock_CapGainTable = []
          var textBlock_CapGainText = []
          var textBlock_CapGainSpecialNotes = []

          textBlock_CapGainTable.push(this.cdata[4][0], this.cdata[4][1])
          textBlock_CapGainText.push(
            "You must review all paragraphs of the treaty article to fully ensure that all conditions are met. Some treaties limit the benefit based on items ",
            "like the percentage of stock ownership held, types of assets held by the corporation, etc. You can access the list of tax treaties here:",
            "https://www.irs.gov/businesses/international-businesses/united-states-income-tax-treaties-a-to-z.",
            "",
            "If a nonresident alien is physically present in the U.S. for less than 183 days during the tax year, none of the capital gains from these sales",
            "are taxable. The days counted for excludable gains consider all days of presence, regardless of exempt days based on visa status under IRC §7701(b).",
            "If the nonresident is present in the U.S. for 183 days or more, generally the rate of tax on the gain is 30% unless a treaty tax rate applies.",
            "",
            "This income is reported on 1040-NR, Schedule NEC, Tax on Income Not Effectively Connected With a U.S. Trade or Business, NOT on Schedule D, ",
            "Capital Gains and Losses, nor on the income section of Form 1040-NR. Capital losses of nonresident aliens may only offset other capital gains. ",
            "(Capital losses of nonresident aliens cannot be used against other income, nor can they be carried forward to another tax year.)",
            ""
          )

          let i = 0
          if (ctbdata[this.countryIndex][9].length > 0) {
            while (i < ctbdata[this.countryIndex][9].length) {
              textBlock_CapGainSpecialNotes.push(ctbdata[this.countryIndex][9][i])
              textBlock_CapGainSpecialNotes.push("")
              i += 1
            }
          }
        }
      }

      sessionStorage.setItem('TB_CapGainTable', JSON.stringify(textBlock_CapGainTable))
      sessionStorage.setItem('TB_CapGainText', JSON.stringify(textBlock_CapGainText))
      sessionStorage.setItem('TB_CapGainSpecialNotes', JSON.stringify(textBlock_CapGainSpecialNotes))

    }

    this.docs.forEach((doc: DocModel) => {
      this.TB_Entries.push(doc.id, doc.doctype, doc.incomecode ?? 'N/A', doc.incomeval)
    })
    sessionStorage.setItem('TB_Entries', JSON.stringify(this.TB_Entries))
  
  
  }

  // Reset entire page when any top level input changes
  selectedVisaChange() {
    this.showStatus = false;
    this.showBenefits = false;
    this.showDocMatches = false;
    this.clearDocs();
  }
  selectedYearChange() {
    this.showStatus = false;
    this.showBenefits = false;
    this.showDocMatches = false;
    this.clearDocs();
  }
  selectedCountryChange() {
    this.showStatus = false;
    this.showBenefits = false;
    this.showDocMatches = false;
    sessionStorage.taxResidentCountry = this.selectedCountry
    this.clearDocs();
  }
  selectedResidencyChange() {
    this.showStatus = false;
    this.showBenefits = false;
    this.showDocMatches = false;
    this.clearDocs();
  }
  selectedDividendChange() {
    this.showStatus = false;
    this.showBenefits = false;
    this.showDocMatches = false;
    this.clearDocs();
  }
  selectedCapgainsChange() {
    this.showStatus = false;
    this.showBenefits = false;
    this.showDocMatches = false;
    this.clearDocs();
  }
}
