import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { DocModel } from './DocsModel';
import { Visa, doctypes, studentVisas, incomecodes, aincomecodes161920, bincomecodes16181920 } from './TBData';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocComponentTb implements OnInit {
  @Output() docChanged = new EventEmitter<DocModel>();
  @Output() docRemoved = new EventEmitter<DocModel>();
  @Input() showLabels: boolean;
  @Input() docId: number;
  doc: DocModel;
  constructor() { }
  
  //incodes = incomecodes;
  incodes = null;
  dtypes = doctypes;

  ngOnInit() {
    if (sessionStorage.taxResidentCountry == "Canada") {
      this.incodes = bincomecodes16181920
    }
    else {
      this.incodes = aincomecodes161920
    }
    
    this.doc = {
      doctype: null,
      incomecode: null,
      incomeval: 0,
      id: this.docId,
    };
  }

  docChange() {
    this.setDocError();
    this.docChanged.emit(this.doc);
  }
  resChange() {
    this.setDocError();
    this.docChanged.emit(this.doc);
  }
  conChange() {
    this.setDocError();
    this.docChanged.emit(this.doc);
  }

  setDocError() {
    this.doc.error = [];
    if (this.doc.doctype === null ) {
      this.doc.error.push('Please select a document type');
    }
    if (this.doc.doctype === "1042-S" &&  this.doc.incomecode === null ) {
      this.doc.error.push('When using the 1042-S you have to select an income code');
    }
  }

  removeDoc() {
    this.docRemoved.emit(this.doc);
  }
}
