<div class="container page-with-footer">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>
        </div>
        <div class="col-6" style="text-align:right">
            <p>Progress: <img src="../../../assets/ProgressBarNR06.jpg" alt='Progress Bar 3 of 10'></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>    <div class="row">
        <div class="col-12">
            <h3>Personalized Guide - U.S. States Income</h3>
            <!--<p>Comment</p>-->
            <hr class="custom-line">
            <p>&nbsp;</p>
        </div>
    </div>

    <div>
        <div class="row">
            <div class="col-sm-5 col-sm-5">
                <label>Select the U.S. states you lived in during {{taxYear}}.</label>
                <div class="row">
                    <div class="col-0">
                    </div>
                </div>
            </div>
            <div class="col-sm-7 col-sm-7">
                <label>Did you receive any income while you lived in this State?</label>
                <div class="row">
                    <div class="col-0">
                    </div>
                    <div class="col-12">
                        <p><small>(Wages, Scholarships, etc.)</small></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-3 col-lg-3">
                <ng-select id="stateLived1" [items]="states" [(ngModel)]="stateLived1" (ngModelChange)="entriesChange()" placeholder="Select state" [clearable]="false"></ng-select>
            </div>
            <div class="col-sm-2 col-lg-2">
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="stateIncome1" [items]="selectYesNo" [(ngModel)]="stateIncome1" (ngModelChange)="entriesChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div>
        </div>
        <div class="row">
            <div>
                <p>&nbsp;</p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-3 col-lg-3">
                <ng-select id="stateLived2" [items]="states" [(ngModel)]="stateLived2" (ngModelChange)="entriesChange()" placeholder="Select state" [clearable]="false"></ng-select>
            </div>
            <div class="col-sm-2 col-lg-2">
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="stateIncome2" [items]="selectYesNo" [(ngModel)]="stateIncome2" (ngModelChange)="entriesChange()" placeholder="Select" [clearable]="false"></ng-select>
            </div>
        </div>
        <div class="row">
            <div>
                <p>&nbsp;</p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-3 col-lg-3">
                <ng-select id="stateLived3" [items]="states" [(ngModel)]="stateLived3" (ngModelChange)="entriesChange()" placeholder="Select state" [clearable]="false"></ng-select> 
            </div>
            <div class="col-sm-2 col-lg-2">
            </div>
            <div class="col-sm-2 col-lg-2">
                <ng-select id="stateIncome3" [items]="selectYesNo" [(ngModel)]="stateIncome3" (ngModelChange)="entriesChange()" placeholder="Select" [clearable]="false"></ng-select> 
            </div> 
        </div>
    </div>

    <div class="row">
        <div *ngIf="errorMismatched" class="col-12">
            <div class="invalid-feedback d-block">Please make sure you have selected both a state and a Yes/No answer for each applicable row.</div>
        </div>
        <div *ngIf="errorNoneYes" class="col-12">
            <div class="invalid-feedback d-block">You have not selected 'Yes' for any rows, but you had indicated you had income.  Please fix.</div>
        </div>
    </div>

    <div>
        <div class="row">
            <div>
                <p>&nbsp;</p>
            </div>
        </div>
    
        <div class="row">
            <div class="col-10">
                <button class="btn btn-danger float-right" (click)="clearEntries()">Clear Entries</button>
            </div> 
        </div>
    </div>

    <div *ngIf="showContinueButton">
        <div class="row">
            <div class="col-12">
                <p>&nbsp;</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitGoToNextPage()">Continue</button>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p><small>If you aren't sure how to respond you can ask us at <a href="mailto:taxassistance@nrvtap.com">taxassistance@nrvtap.com</a>.</small></p>
        </div>
    </div>


</div>