/**
 * This file contains all the meta data needed for Visas.
 */

 /**
  * All Visas that we currently support
  */
  export enum Visa {
    F1 = 'F-1 Student',
    J1Student = 'J-1 Student',
    J1NonStudent = 'J-1 Non-Student',
    F2 = 'F-2 Student',
    J2Student = 'J-2 Student',
    J2NonStudent = 'J-2 Non-Student',
    M1 = 'M-1 Student',
    M2 = 'M-2 Student',
    Q1Student = 'Q-1 Student',
    Q1NonStudent = 'Q-1 Non-Student',
    Q2Student = 'Q-2 Student',
    Q2NonStudent = 'Q-2 Non-Student',
    NonExemptVisa = 'Non-exempt visa (B1/B2, TN, H1-b, etc.)',
    OtherExemptVisa = 'Other Exempt visa (A, G, NATO, etc.)'
}

export enum Residencies {
    ResA = 'Resident Alien',
    NonResA = 'Nonresident Alien',
}

export enum errom {
    ast = "Bangalore",
    w  = "Special statement - w : The rate applies to dividends paid by a regulated investment company (RIC) or a real estate investment trust (REIT). However, that rate applies to dividends paid by a REIT only if the beneficial owner of the dividends is an individual holding less than a 10% interest (25%i n the case of Portugal, Spain, Thailand, and Tunisia) in the REIT.",
    dd = "Special statement - dd : Amounts paid to certain pension funds that are not derived from the carrying on of a business, directly or indirectly, by the fund are exempt. This includes dividends paid   by a REIT only if the conditions in footnote mm are met. For Sweden, to be entitled to the exemption, the pension fund must not sell or make a contract to sell the holding from which the dividend is derived within 2 months of the date the pension fund acquired the holding. The United States has competent authority arrangements (MAP) with some treaty jurisdictions (e.g. Netherlands and Switzerland) that describe which pension funds are eligible for the exemption. See the Competent Authority Arrangements page on irs.gov.",
    ff = "Special statement - ff : The rate applies to dividends paid by a regulated investment company (RIC). Dividends paid by a real estate investment trust (REIT) are subject to a 30% rate.",
    gg = "Special statement - gg : In Sri Lanka, the rate applies to dividends paid by a real estate investment trust (REIT) only if the beneficial owner of the dividends is (a)a n individual holding less than a 10% interest in the REIT, (b) a person holding not more than 5% of any class of the REIT's stock and the dividends are paid on stock that is publicly traded, or (c) a person holding not more than a 10% interest in the REIT and the REIT is diversified.",
    mm = "Special statement - mm : The rate applies to dividends paid by a regulated investment company (RIC) or real estate investment trust (REIT). However, that rate applies to dividends paid by a REIT only if the beneficial owner of the dividends is (a) an individual (or pension fund,i n some cases) holding not more than a 10% interest in the REIT, (b) a person holding not more than 5% of any class of the REIT's stock and the dividends are paid on stock that is publicly traded, or (c) a person holding not more than a 10% interest in the REIT and the REIT is diversified.",
    rr = "Special statement - rr : In Barbados, no benefits for interest, dividends or royalties are permitted if recipient is subject to a special tax regime or administrative practice that provides for an effective tax rate substantially lower than the generally applicable tax rate for companies or individuals as appropriate",
    pp = "Special statement - pp : The rate applies to dividends paid by a regulated investment company (RIC) or real estate investment trust (REIT). However, that rate applies to dividends paid by a REIT only if the beneficial owner of the dividends is (a) an individual holding not more than a 25% interest in the REIT, (b) a person holding not more than 5% of any class of the REIT's stock and the dividends are paid on stock that is publicly traded, or (c) a person holding not more than a 10% interest in the REIT and the REIT is diversified, or (d) a Dutch belegginginstelling.",
    bangl = "2-year limit applies to business or technical apprentices.",
    l = "Treaty contains provisions that retroactively eliminates benefits if the allowable period in the U.S. or income amounts are exceeded as defined in the treaty.",
    l1 = "The treaty benefit for wage income is retroactively eliminated if the duration of your visit exceeds 2 years.  If this happens you will owe income tax on all of your income for the entire visit.  If you know that you will exceed this 2 year limit, do not claim any treaty benefit when you file your tax return.  In this situation, you would use Basic Scenario 3.",
    l2 = "If you originally claim the treaty and then stay past 2 years you will need to amend your previous returns, pay the tax that you owed on that income, plus penalties and interest as the tax should have been paid with the original return back when it was due.",
    l3 = "If you don't claim the treaty, and then leave before the 2 years are up, you can also file an amended return to claim a refund of the tax you have paid.  You won't owe any interest or penalties in this situation.  ",
    l4 = "If you are not sure how long your stay will be, we strongly encourage you to not claim the treaty, and then file an amended return once you are absolutely certain you will be leaving within the 2 year time limit.  ",
    ttnote = "Tax Treaty provisions allowed federally may not be honored by some states. Contact your state to see if treaty provisions are honored on the state return.",
    divnote = "NRAs residing in the U.S. for less than 183 days in the tax year, generally are exempt from tax on Capital gains from U.S. stock sales.",
    commonwealth = "Commonwealth of Independent States (Armenia, Azerbaijan, Belarus, Georgia, Kyrgyzstan, Moldova, Tajikistan, Turkmenistan and Uzbekistan.) Generally, limited to $10,000 p.a. of scholarship/fellowship income to provide ordinary living expenses.",
    commonlist = "Commonwealth of Independent States (Armenia, Azerbaijan, Belarus, Georgia, Kyrgyzstan, Moldova, Tajikistan, Turkmenistan and Uzbekistan.)",
}

export enum utp {
    i1 = "An Indian student or apprentice may take a standard deduction equal to the amount allowable on Form 1040 and may be able to claim the personal exemptions for a nonworking spouse and U.S. born-children. However, benefits will be limited to certain credits, as the allowable exemption deduction is currently -0- until 2025.",
    i2 = "Treaty benefits for a scholar from India are very different from those for a student. The scholar benefit for income code 19 is lost retroactively if the visit exceeds 2 years.",
    i3 = "Generally, the standard deduction for Single taxpayers and Married Filing Separately taxpayers in 2021 is $12,550.",
    i4 = "Nonresident aliens can't file a joint return. Even though a student from India may be able to take an exemption for a nonworking spouse, this is not considered a joint return. Thus, the standard deduction for married filing separately must be used. In determining their tax liability, they must use the tax tables or tax rate schedules for married filing separately.",
    ch1 = "Almost all U.S. tax treaties are limited to a specific number of years and may not be available for U.S. residents for tax purposes. An exception is the U.S.- China Treaty. Its provisions are not limited by year restrictions.",
    ch2 = "Also: This treaty is not applicable to Chinese citizens who are residents of Hong Kong, Macao, or Taiwan.",
    ch3 = "The U.S. treaty with China provides that a scholar is exempt from tax on earned income for 3 years. After 2 years, a scholar will become a resident alien for tax purposes but is still entitled to 1 more year of tax benefits under the treaty. The treaty also provides that students have an exemption of up to $5,000 per year for income earned while they are studying or training. In most cases, the student will become a resident for federal tax purposes in their sixth calendar year. Students from China can continue to claim the treaty benefits on their resident alien tax return (if they still meet the definition of a student).",
    ca1 = "The students and scholars are permitted to use Article 15 of the tax treaty, which applies to dependent personal services.",
    ca2 = "The tax treaty with Canada is different from most other tax treaties because it (1) exempts all earned income if the nonresident earned not more than $10,000 in the tax year, but (2) taxes all income if the nonresident earned more than $10,000. This treaty benefit is lost if the nonresident becomes a resident for tax purposes.",
}

// [0] = Country Name
// [1] = Code 16[Year,Limit,Treaty] - Student
// [2] = Code 19[Year,Limit,Treaty] - Non-Student
// [3] = Code 20[Year,Limit,Treaty] - Student
// [4] = Capital Gains[Rate,Treaty]
// [5] = Dividend Gains[Rate,Treaty]    
// [6] = Code16-SM
// [7] = Code19-SM
// [8] = Code20-SM
// [9] = Capital Gains
// [10] = Dividend

export var ctbdata = [
    //                                                                                                                                                                                                  SM = Special Messages    
    // Country Name                                   Code 16[Year,Limit,Treaty]              Code 19[Year,Limit,Treaty]      Code 20[Year,Limit,Treaty]           Capital Gains[Rate,Treaty]         Dividend Gains[Rate,Treaty]               Code16-SM                       Code19-SM                                     Code20-SM               Capital Gains           Dividend
    [ ""                                            , [null]                                , [null]                        , [null]                             , [null]                           , [null]                                  , []                            , []                                          , []                    , []                    , []                            , [0,0,0]          , []                                          ],  
    [ "Australia"                                   , [null]                                , [null]                        , [null]                             , [30, "None"]                     , [15, "10(2)/P6"]                        , []                            , []                                          , [errom.bangl]         , []                    , [errom.mm,]                   , [0,0,0]          , []                                          ],  
    [ "Austria"                                     , [null]                                , [null]                        , [null]                             , [0, "13(6)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.w,]                    , [0,0,0]          , []                                          ],  
    [ "Bangladesh"                                  , ["No Limit", "No Limit", "21(2)"]     , [2, "No Limit", "21(1)",]     , ["No Limit", 8000, "21(2)"]        , [0, "13(4)"]                     , [15, "10(2)"]                           , [errom.bangl]                 , [errom.bangl]                               , [errom.bangl]         , []                    , [errom.mm,]                   , [0,0,0]          , []                                          ],             
    [ "Barbados"                                    , [null]                                , [null]                        , [null]                             , [0, "13(6)"]                     , [15, "10(2)/1PIII(1); 2PII(6)"]         , []                            , []                                          , []                    , []                    , [errom.mm,errom.rr,]          , [0,0,0]          , []                                          ],  
    [ "Belgium"                                     , [null]                                , [2, "No Limit", "19(2)",]     , ["No Limit", 9000, "19(1)(b)"]     , [30, "13(3)"]                    , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.dd,errom.mm,]          , [0,1,0]          , []                                          ],  
    [ "Bulgaria"                                    , [null]                                , [2, "No Limit", "19(2)",]     , ["No Limit", 9000, "19(1)(b)"]     , [0, "13(8)"]                     , [10, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.dd,errom.mm,]          , [0,0,0]          , []                                          ],  
    [ "Canada"                                      , [null]                                , ["N/A", 10000, "XV"]          , ["N/A", 10000, "XV"]               , [0, "XIII(4)"]                   , [15, "X(2)/ 5P5(1)"]                    , []                            , [utp.ca1,utp.ca2]                           , [utp.ca1,utp.ca2]     , []                    , [errom.mm,]                   , [0,0,0]          , []                                          ],  
    [ "China"                                       , ["No Limit", "No Limit", "20(b)"]     , [3, "No Limit", "19",]        , ["No Limit", 5000, "20(c)"]        , [30, "12"]                       , [10, "9(2)"]                            , []                            , [utp.ch1,utp.ch2,utp.ch3,]                  , []                    , []                    , []                            , [1,1,1]          , []                                          ],  
    [ "Commonwealth of Independent States"          , [5, 10000, "VI(1)"]                   , [2, "No Limit", "VI(1)",]     , [null]                             , [0, "III(1)(b)"]                 , [30, "None"]                            , [errom.commonwealth,]         , []                                          , []                    , [errom.commonlist,]   , [errom.commonlist,]           , [1,1,0]          , []                                          ],  
    [ "Cyprus"                                      , [5, "No Limit", "21(1)"]              , [null]                        , [5, 2000, "21(1)"]                 , [0, "16(1)"]                     , [15, "12(2)"]                           , []                            , []                                          , []                    , []                    , []                            , [0,0,0]          , []                                          ],  
    [ "Czech Republic"                              , [5, "No Limit", "21(1)"]              , [2, "No Limit", "21(5)",]     , [5, 5000, "21(1)"]                 , [0, "13(6)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.w,]                    , [1,1,1]          , []                                          ],  
    [ "Denmark"                                     , [null]                                , [null]                        , [null]                             , [0, "13(6)"]                     , [15, "10(2)/ PII"]                      , []                            , []                                          , []                    , []                    , [errom.dd,errom.mm,]          , [0,0,0]          , []                                          ],  
    [ "Egypt"                                       , [5, "No Limit", "23(1)"]              , [2, "No Limit", "22",]        , [5, 3000, "23(1)"]                 , [30, "14(1)(d)"]                 , [15, "11(2)"]                           , []                            , []                                          , []                    , []                    , []                            , [1,1,1]          , []                                          ],  
    [ "Estonia"                                     , [5, "No Limit", "20(1)"]              , [null]                        , [5, 5000, "20(1)"]                 , [0, "13(6)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.w,]                    , [1,0,1]          , []                                          ],  
    [ "Finland"                                     , [null]                                , [null]                        , [null]                             , [0, "13(6)"]                     , [15, "10(2)/ PIII"]                     , []                            , []                                          , []                    , []                    , [errom.dd,errom.mm,]          , [0,0,0]          , []                                          ],  
    [ "France"                                      , [5, "No Limit", "21(1)"]              , [2, "No Limit", "20",]        , [5, 5000, "21(1)"]                 , [0, "13(6)"]                     , [15, "10(2)/ 2P2"]                      , []                            , []                                          , []                    , []                    , [errom.mm,]                   , [1,1,1]          , []                                          ],  
    [ "Georgia"                                     , [5, 10000, "VI(1)"]                   , [2, "No Limit", "VI(1)",]     , [null]                             , [0, "III(1)(b)"]                 , [30, "None"]                            , [errom.commonwealth,]         , []                                          , []                    , []                    , []                            , [1,1,0]          , []                                          ],  
    [ "Germany"                                     , ["No Limit", "No Limit", "20(3)"]     , [2, "No Limit", "21(1)",]     , ["4l", 9000, "20(4)"]              , [0, "13(5)"]                     , [15, "10(2)/ PIV"]                      , []                            , []                                          , [errom.l]             , []                    , [errom.dd,errom.mm,]          , [1,1,1]          , []                                          ],  
    [ "Greece"                                      , [null]                                , [3, "No Limit", "XII",]       , [null]                             , [30, "None"]                     , [30, "none"]                            , []                            , []                                          , []                    , []                    , []                            , [0,0,0]          , []                                          ],  
    [ "Hungary"                                     , [null]                                , [2, "No Limit", "17",]        , [null]                             , [0, "12(3)"]                     , [15, "9(2)"]                            , []                            , []                                          , []                    , []                    , []                            , [0,1,0]          , []                                          ],  
    [ "Iceland"                                     , [5, "No Limit", "19(1)"]              , [null]                        , [5, 9000, "19(1)"]                 , [0, "13(6)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.dd,errom.mm,]          , [1,1,1]          , []                                          ],  
    [ "India"                                       , [null]                                , [2, "No Limit", "22",]        , [null]                             , [30, "13"]                       , [25, "10(2)"]                           , []                            , [errom.l1,errom.l2,errom.l3,errom.l4]       , []                    , []                    , [errom.w,]                    , [0,1,0]          , []                                          ],  
    [ "Indonesia"                                   , [5, "No Limit", "19(1)"]              , [2, "No Limit", "20",]        , [5, 2000, "19(1)"]                 , [30, "14(2)(b)"]                 , [10, "11(2)/ P1"]                       , []                            , []                                          , []                    , []                    , []                            , [1,1,1]          , []                                          ],  
    [ "Ireland"                                     , [null]                                , [null]                        , [null]                             , [0, "13(5)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.mm,]                   , [0,0,0]          , []                                          ],  
    [ "Israel"                                      , [5, "No Limit", "24(1)"]              , [2, "No Limit", "23",]        , [5, 3000, "24(1)"]                 , [30, "15(1)(d)"]                 , [25, "12(2)"]                           , []                            , []                                          , []                    , []                    , [errom.w,]                    , [1,1,1]          , []                                          ],  
    [ "Italy"                                       , [null]                                , [2, "No Limit", "20",]        , [null]                             , [0, "13(4)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.mm,]                   , [0,1,0]          , []                                          ],  
    [ "Jamaica"                                     , [null]                                , [2, "No Limit", "22",]        , [null]                             , [0, "13(6)"]                     , [15, "10(2)/ P2"]                       , []                            , []                                          , []                    , []                    , []                            , [0,1,0]          , []                                          ],  
    [ "Japan"                                       , [null]                                , [2, "No Limit", "20",]        , [null]                             , [0, "13(7)"]                     , [10, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.dd,errom.mm,]          , [0,1,0]          , []                                          ],  
    [ "Kazakhstan"                                  , [5, "No Limit", "19"]                 , [null]                        , [null]                             , [0, "13(6)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.ff,]                   , [0,0,0]          , []                                          ],  
    [ "Korea, South"                                , [5, "No Limit", "21(1)"]              , [2, "No Limit", "20",]        , [5, 2000, "21(1)"]                 , [30, "16(1)(c)(ii)"]             , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , []                            , [1,1,1]          , []                                          ],  
    [ "Latvia"                                      , [5, "No Limit", "20(1)"]              , [null]                        , [5, 5000, "20(1)"]                 , [0, "13(6)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.w,]                    , [1,0,1]          , []                                          ],  
    [ "Lithuania"                                   , [5, "No Limit", "20(1)"]              , [null]                        , [5, 5000, "20(1)"]                 , [0, "13(6)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.w,]                    , [1,0,1]          , []                                          ],  
    [ "Luxembourg"                                  , [null]                                , [2, "No Limit", "21(2)"]      , [2, "No Limit", "21(2)"]           , [0, "14(5)"]                     , [15, "10(2)"]                           , []                            , [errom.l1,errom.l2,errom.l3,errom.l4,]      , [errom.l]             , []                    , [errom.w,]                    , [0,1,0]          , []                                          ],  
    [ "Malta"                                       , [null]                                , [null]                        , ["No Limit", 9000, "20(2)"]        , [0, "13(6)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.dd,errom.mm,]          , [0,0,0]          , []                                          ],  
    [ "Mexico"                                      , [null]                                , [null]                        , [null]                             , [0, "13(7)"]                     , [10, "10(2)/ 2PII"]                     , []                            , []                                          , []                    , []                    , [errom.dd,errom.mm,]          , [0,0,0]          , []                                          ],  
    [ "Morocco"                                     , [5, "No Limit", "18"]                 , [null]                        , [5, 2000, "18"]                    , [30, "13(2)(c)(ii)"]             , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , []                            , [1,0,1]          , []                                          ],  
    [ "Netherlands"                                 , [3, "No Limit", "22(2)"]              , [2, "No Limit", "21(1)"]      , ["No Limit", 2000, "22(1)"]        , [0, "14(7)"]                     , [15, "10(2)/ P3(a)"]                    , []                            , [errom.l1,errom.l2,errom.l3,errom.l4,]      , []                    , []                    , [errom.dd,errom.pp,]          , [1,1,1]          , []                                          ],  
    [ "New Zealand"                                 , [null]                                , [null]                        , [null]                             , [0, "13(7)"]                     , [15, "10(2)/ PVI"]                      , []                            , []                                          , []                    , []                    , [errom.mm,]                   , [0,0,0]          , []                                          ],  
    [ "Norway"                                      , [5, "No Limit", "16(1)"]              , [2, "No Limit", "15",]        , [5, 2000, "16(1)"]                 , [30, "12(1)(c)(ii)"]             , [15, "8(2)/ PVI(1)"]                    , []                            , [errom.l1,errom.l2,errom.l3,errom.l4]       , []                    , []                    , []                            , [0,0,0]          , []                                          ],  
    [ "Pakistan"                                    , [null]                                , [2, "No Limit", "XII",]       , [5, 5000, "XII(I)",]               , [30, "none"]                     , [30, "VII(2)/ VI(1)"]                   , []                            , [errom.l1,errom.l2,errom.l3,errom.l4]       , []                    , []                    , []                            , [0,0,0]          , []                                          ],  
    [ "Philippines"                                 , [5, "No Limit", "22(1)"]              , [2, "No Limit", "21",]        , [5, 3000, "22(1)"]                 , [0, "14(2)"]                     , [25, "11(2)"]                           , []                            , []                                          , []                    , []                    , []                            , [1,1,1]          , []                                          ],  
    [ "Poland"                                      , [5, "No Limit", "18(1)"]              , [2, "No Limit", "17",]        , [5, 2000, "18(1)"]                 , [0, "14(7)"]                     , [15, "11(2)"]                           , []                            , []                                          , []                    , []                    , []                            , [1,1,1]          , []                                          ],  
    [ "Portugal"                                    , [5, "No Limit", "23(1)"]              , [2, "No Limit", "22",]        , [5, 5000, "23(1)"]                 , [0, "14(6)"]                     , [15, "10(2), (3)"]                      , []                            , []                                          , []                    , []                    , [errom.w,]                    , [1,1,1]          , []                                          ],  
    [ "Romania"                                     , [5, "No Limit", "20(1)"]              , [2, "No Limit", "19",]        , [5, 2000, "20(1)"]                 , [30, "13(1)(b)"]                 , [10, "10(2)"]                           , []                            , []                                          , []                    , []                    , []                            , [1,1,1]          , []                                          ],  
    [ "Russia"                                      , [5, "No Limit", "18"]                 , [null]                        , [null]                             , [0, "21(4)"]                     , [10, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.ff,]                   , [0,0,0]          , []                                          ],  
    [ "Slovak Republic"                             , [5, "No Limit", "21(1)"]              , [2, "No Limit", "21(5)",]     , [5, 5000, "21(1)"]                 , [0, "13(6)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.w,]                    , [1,1,1]          , []                                          ],  
    [ "Slovenia"                                    , [5, "No Limit", "20(1)"]              , [2, "No Limit", "20(3)",]     , [5, 5000, "20(1)"]                 , [0, "13(5)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.mm,]                   , [1,1,1]          , []                                          ],  
    [ "South Africa"                                , [null]                                , [null]                        , [null]                             , [0, "13(5)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.w,]                    , [0,0,0]          , []                                          ],  
    [ "Spain"                                       , [5, "No Limit", "22(1)"]              , [null]                        , [5, 5000, "22(1)"]                 , [0, "13(7)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.w,]                    , [1,0,1]          , []                                          ],  
    [ "Sri Lanka"                                   , [null]                                , [null]                        , [null]                             , [0, "13(7)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.gg,]                   , [0,0,1]          , []                                          ],  
    [ "Sweden"                                      , [null]                                , [null]                        , [null]                             , [0, "13(6)"]                     , [15, "10(2)/ PIV"]                      , []                            , []                                          , []                    , []                    , [errom.dd,errom.mm,]          , [0,0,0]          , []                                          ],  
    [ "Switzerland"                                 , [null]                                , [null]                        , [null]                             , [0, "13(5)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.w,errom.dd]            , [0,0,0]          , []                                          ],  
    [ "Thailand"                                    , [5, "No Limit", "22(1)"]              , [2, "No Limit", "23",]        , [5, 3000, "22(1)"]                 , [30, "13"]                       , [15, "10(2)"]                           , []                            , [errom.l1,errom.l2,errom.l3,errom.l4,]      , []                    , []                    , [errom.w]                     , [1,1,1]          , []                                          ],  
    [ "Trinidad and Tobago"                         , [5, "No Limit", "19(1)"]              , [2, "No Limit", "18",]        , [5, 2000, "19(1)"]                 , [30, "---"]                      , [30, "12(1)"]                           , []                            , []                                          , []                    , []                    , []                            , [1,1,1]          , []                                          ],  
    [ "Tunisia"                                     , [5, "No Limit", "20"]                 , [null]                        , [5, 4000, "20"]                    , [0, "13(5)"]                     , [20, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.w,]                    , [1,0,1]          , []                                          ],  
    [ "Turkey"                                      , [null]                                , [2, "No Limit", "20(2)",]     , [null]                             , [0, "13(5)"]                     , [20, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.w,]                    , [0,0,0]          , []                                          ],  
    [ "Ukraine"                                     , [5, "No Limit", "20"]                 , [null]                        , [null]                             , [0, "13(4)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.ff,]                   , [0,0,0]          , []                                          ],  
    [ "United Kingdom"                              , [null]                                , [2, "No Limit", "20A",]       , [null]                             , [30, "13"]                       , [15, "10(2)"]                           , []                            , [errom.l1,errom.l2,errom.l3,errom.l4,]      , []                    , []                    , [errom.mm,]                   , [0,1,0]          , []                                          ],  
    [ "Venezuela"                                   , [5, "No Limit", "21(1)"]              , [2, "No Limit", "21(3)",]     , [5, 5000, "21(1)"]                 , [0, "13(5)"]                     , [15, "10(2)"]                           , []                            , []                                          , []                    , []                    , [errom.mm,]                   , [1,1,1]          , []                                          ],  
    ];



    export var ctbdataCountryOnly = [
        "",
        "Australia",
        "Austria",  
        "Bangladesh",                                           
        "Barbados",                                   
        "Belgium",                                    
        "Bulgaria",                                   
        "Canada",                                     
        "China",                                      
        "Commonwealth of Independent States",         
        "Cyprus",                                     
        "Czech Republic",                             
        "Denmark",                                    
        "Egypt",                                      
        "Estonia",                                    
        "Finland",                                    
        "France",                                     
        "Georgia",                                    
        "Germany",                                    
        "Greece",                                     
        "Hungary",                                    
        "Iceland",                                    
        "India",                                      
        "Indonesia",                                  
        "Ireland",                                    
        "Israel",                                     
        "Italy",                                      
        "Jamaica",                                    
        "Japan",                                      
        "Kazakhstan",                                 
        "Korea, South",                               
        "Latvia",                                     
        "Lithuania",                                  
        "Luxembourg",                                 
        "Malta",                                      
        "Mexico",                                     
        "Morocco",                                    
        "Netherlands",                                
        "New Zealand",                                
        "Norway",                                     
        "Pakistan",                                   
        "Philippines",                                
        "Poland",                                     
        "Portugal",                                   
        "Romania",                                    
        "Russia",                                     
        "Slovak Republic",
        "Slovenia",
        "South Africa",
        "Spain",
        "Sri Lanka",
        "Sweden",
        "Switzerland",
        "Thailand",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Ukraine",
        "United Kingdom",
        "Venezuela",
        ]
    



/**
 * Different Categories of Visas used by the NRA Tool
 */
export enum VisaType {
    Student,
    NonStudent,
    NonExempt,
    Exempt
}

/**
 * Visas that are considered to be Student visas
 */
 export const studentVisas: Visa[] = [
    Visa.F1,
    Visa.F2,
    Visa.J1Student,
    Visa.J2Student,
    Visa.M1,
    Visa.M2,
    Visa.Q1Student,
    Visa.Q2Student
];

export const resStatuses: Residencies[] = [
    Residencies.ResA,
    Residencies.NonResA,
];



export const doctypes = [
    "W-2",
    "1042-S",
    "Fellowship",
];

export const incomecodes = [
    "16",
    "19",
    "20",
];


export const aincomecodes161920 = [
    "16",
    "19",
    "20",
];

export const bincomecodes16181920 = [
    "16",
    "18",
    "19",
    "20",
];

/*
ENSURE COUNTRY NAMES OF LIST ABOVE MATCH THE COUNTRY LIST ON PG-NR01

If any of top 4 cases below are yes checked and non-null entry in table above then
- go to TB page
- enter income documents
- based on if the treaty benefits match or not determines which PDF guide
- if they don't match

Generic outputs of Top 5 below..
Wage TB match or not
Scholarship... TB match or not 
- Guides printed based on the match/non-match and income documents above

W-2
- If yes, and if a non-null entry for country of Tax Res. in table above (Code19) if J1NS, or (Code 20) if F/J student visa, then
    - go to TB page
- If yes and null entry for that (country and visa type) then skip TB
- If no, skip TB page


1042-S Box 1 income code 19
- If yes, and if a non-null entry for country of Tax Res. in table above (Code19) and J1NS visa, then
    - go to TB page
- If yes and null entry then
    - skip TB
    - Add error message in a PDF that "should not have Treaty Benefit"
- If no, skip TB page


1042-S Box 1 income code 20
- If yes, and if a non-null entry for country of Tax Res. in table above (Code 20) if F/J student visa, then
    - go to TB page
- If yes and null entry then
    - skip TB
    - Add error message in a PDF that "should not have Treaty Benefit"
- If no, skip TB page


1042-S Box 1 income code 16 - Scholarship or Fellowship
- If yes, and if a non-null entry for country of Tax Res. in table above (Code16) ... then
    - Go to TB page
    - Enter income documents (include "For a W-2..." text)
    - create PDF with "Match Status for Scholarships" info
    - PDF of Entering Fellowship Income on 1042-S and TB
- If yes and no entry then PDF of Fellowship not reported on 1042-S without TB
- if no, skip TB


Scholarship or Fellowship not reported on an income document
- If yes, and if a non-null entry for country of Tax Res. in table above (Code16) ... then
    - Go to TB page
    - Enter income documents (include "For a W-2..." text)
    - create PDF with "Match Status for Scholarships" info
    - PDF of Fellowship not reported on 1042-S with TB
- If yes and no entry then PDF of Fellowship not reported on 1042-S without TB
- if no, skip TB

1099-INT
- IF yes, Skip TB page and follow up Q... Did you have any fed tax withheld in box 3?  Y/N
    - If Yes, PDF Interest with Tax withheld
    - If no, PDF of BAnk interest
- if no, skip TB

1099-DIV
- If yes, and if a non-null entry for country of Tax Res. in table above for Dividend then
    create PDF with Dividend printout from TB tool (Dividend & Limit table & wording underneath)
    and also include PDF Cap Gain entry guide
- If yes and no entry then Skip TB and include PDF Dividend guide
- If no, Skip TB

1099-B
- If yes, and if a non-null entry for country of Tax Res. in table above for Captial Gains then
    create PDF with Capital Gains printout from TB tool (Captial Gain & Limit table & wording underneath)
    and also include PDF Cap Gain entry guide
- If yes and no entry then Skip TB and include PDF Captial Gains guide
- If no, Skip TB

1099-R
- Skip TB page
- If yes, PDF of 1099-Retirement distributions

1099-Misc
- Skip TB page
- If yes, PDF of "1099-Misc income is Out Scope..."

1099-NEC
- Skip TB page
- If yes, PDF of "1099-NEC income is Out Scope..."

1042-S (interest)
- Marea to look up income code to add to the description on pg-nr04
- IF yes, Skip TB page and follow up Q... Did you have any fed tax withheld in box 3?  Y/N
    - If Yes, PDF Interest with Tax withheld
    - If no, PDF of BAnk interest
- if no, skip TB

1042-S (dividends)
- Marea to look up income code to add to the description on pg-nr04
- If yes, and if an entry for country of Tax Res. in table above then
    create PDF with Dividend printout from TB tool (Dividend rate Gain & Limit table & wording underneath)
    and also include PDF dividend entry guide
- If yes and no entry then Skip TB and include PDF dividend entry guide
- If no, Skip TB

Other income not listed
- Skip TB page
- PDF page of Contact us for more info about your other income

No U.S. income
- Skip TB page
- PDF 8843-only guide
- follow up Q... Do you have a SSN or ITIN?  Y/N  - record answer




*/