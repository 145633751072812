import * as moment from 'moment';

import { Visa, VisaType, studentVisas } from './Visa';
import { VisitModel } from './VisitModel';

/**
 * This is an object representation of Visit. It encapsulates all information and logic needed by the NRA Tool to
 * compute residency status.
 */
export class Visit {
    private visa: Visa;
    private visaType: VisaType;
    private entry: moment.Moment;
    private exit: moment.Moment;
    private exemptYears: number[];
    private nonExemptYears: number[];
    private yearsPresent: number[] = [];
    private id: number;
    private error: string[];

    /**
     * This method is a method used by all Visit objects to compute the number of days between two dates
     * including both the end date and start date.
     * @param firstDate - The date coming first in the calendar
     * @param secondDate - The date coming last in the calendar
     */
    static getNumberOfDaysBetweenDatesInclusive(firstDate: moment.Moment, secondDate: moment.Moment): number {
        return secondDate.endOf('day').diff(firstDate.startOf('day'), 'days') + 1;
    }

    /**
     * Builds a new instance of a Visit.
     * Sets any values passed into the constructor.
     * Sets property defaults.
     * Sets properties that can be inferred by the provided information.
     * @param id - The visits ID
     * @param visa - The Visa held during this visit
     * @param entry - The first date in the visit
     * @param exit - The last date in the visit (can be null)
     * @param error - Any errors flagged by the UI
     */
    constructor(
        id: number,
        visa: Visa,
        entry: moment.Moment,
        exit: moment.Moment,
        error?: string[]
    ) {
        this.id = id;
        this.visa = visa;
        this.entry = entry;
        this.exit = exit;
        this.exemptYears = [];
        this.nonExemptYears = [];
        this.error = error;
        this.setYearsPresent();
        this.setVisaType();
    }

    /**
     * Private methods that can only be used internally by a Visit instance
     * ====================================================================
     */

    /**
     * Sets the visaType property for this visit based on the Visa passed in the constructor.
     * Can be Student, NonExempt, Exempt, and NonStudent.
     */
    private setVisaType() {
        if (studentVisas.includes(this.visa)) {
            this.visaType = VisaType.Student;
        } else if (this.visa === Visa.NonExemptVisa) {
            this.visaType = VisaType.NonExempt;
        } else if (this.visa === Visa.OtherExemptVisa) {
            this.visaType = VisaType.Exempt;
        } else {
            this.visaType = VisaType.NonStudent;
        }
    }

    /**
     * Sets the all the years that this visit takes place in based on the entry and exit date.
     * The yearsPresent property will become an array of years
     */
    private setYearsPresent() {
        if (this.entry) {
            const firstYearPresent = this.entry.year();
            const lastYearPresent = this.exit ? this.exit.year() : moment().year();
            for (let year = firstYearPresent; year <= lastYearPresent; year++) {
                this.yearsPresent.push(year);
            }
        }
    }
    /**
     * End Private Methods
     * ===================
     */

    /**
     * Adds the sepcified year to the exemptYears array
     * @param year - the year to add to the exemptYears array
     */
    addExemptYear(year: number) {
        this.exemptYears.push(year);
    }

    /**
     * Adds the sepcified year to the nonExemptYears array
     * @param year - the year to add to the nonExemptYears array
     */
    addNonExemptYear(year: number) {
        this.nonExemptYears.push(year);
    }

    /**
     * Returns the VisitModel representation of this Visit instance.
     * This is the type of object that the UI produces and consumes.
     */
    getVisitModel(): VisitModel {
        return {
            id: this.id,
            visa: this.visa,
            entry: this.entry,
            exit: this.exit,
            error: this.error
        };
    }

    /**
     * Public methods that can be called on an instance of this class
     * ==============================================================
     */

    /**
     * Get the number of nonExempt days in a specified year for this visit.
     * Check the nonExemptYears array, if it includes the specified year return the number of days present in that year
     * Otherwise, return 0;
     * @param year - Year we want to check for Non Exempt days
     */
    getNumberOfNonExemptDaysInYear(year: number): number {
        // If this year is a Non Exempt year
        if (this.nonExemptYears.includes(year)) {
            return this.getNumberOfPresentDaysInYear(year);
        // If year is an exempt year
        } else {
            return 0;
        }
    }

    /**
     * Get the number of days present in the specified year for this visit.
     * If the visit overlaps with other years, the beginning and/or the end date of the year is used to calculate the days present
     * @param year - Year we want the number of days present for.
     */
    getNumberOfPresentDaysInYear(year: number): number {
        const beginningOfYear: moment.Moment = moment(`${year}-01-01`);
        const endOfYear: moment.Moment = moment(`${year}-12-31`);
        // If the entry date is after year specified
        if (this.entry.year() > year) {
            return 0;
        // If the entry is in the year specified
        } else if (this.entry.year() === year) {
            // If the exit is null or the exit is after specified year
            if (!this.exit || this.exit.year() > year) {
                return Visit.getNumberOfDaysBetweenDatesInclusive(this.entry, endOfYear);
            // If the exit is in the year specified
            } else {
                return Visit.getNumberOfDaysBetweenDatesInclusive(this.entry, this.exit);
            }
        // If the entry is before the year specified
        } else {
            // If the exit is null or the exit is after the year specified
            if (!this.exit || this.exit.year() > year) {
                return Visit.getNumberOfDaysBetweenDatesInclusive(beginningOfYear, endOfYear);
            // If the exit is in the year specified
            } else {
                return Visit.getNumberOfDaysBetweenDatesInclusive(beginningOfYear, this.exit);
            }
        }

    }

    /**
     * Simple getter methods that retrieve values and can be called on the instance of this class
     * ========================================================================
     */
    getVisa(): Visa { return this.visa; }

    getVisaType(): VisaType { return this.visaType; }

    getEntry(): moment.Moment { return this.entry; }

    getExit(): moment.Moment { return this.exit; }

    getExemptYears(): number[] { return this.exemptYears; }

    getNonExemptYears(): number[] { return this.nonExemptYears; }

    getYearsPresent(): number[] { return this.yearsPresent; }

    /**
     * End Public Methods
     * ==================
     */
}
