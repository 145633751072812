<div class="container page-with-footer">
  <div class="row">
    <div class="col-12">
      <h3>What is my Minnesota Residency?</h3>
      <p>This tool is intended to assist you in determining your MN tax residency status.</p>
      <div class="alert alert-custom">
        <p class="mb-0"><b>It is your individual responsibility to determine your Minnesota tax residency. Your
            Minnesota residency will depend on where you physically were present during the calendar year and what your
            living situation was during the year. Please see the Minnesota Department of Revenue
            <a href="https://www.revenue.state.mn.us/sites/default/files/2022-01/fs16_22.pdf">Fact Sheet 16, Aliens</a>
            for additional guidance. </b></p>
        <p class="mb-0">Definition: An <b>abode</b> is a residence in Minnesota that is suitable for year-round use, and
          is equipped with its own cooking and bathing facilities. Houses and apartments are usually abodes, but dorm
          rooms and hotel rooms often are not.
        </p>
      </div>
      <hr class="custom-line">
    </div>
  </div>
  <div *ngIf="!PrintMode" id="entry-section">

    <div *ngIf="navFrompg" class="row mb-2">
      <div class="col-sm-3 col-lg-2">
          <label for="selectedTaxYear" class="h5">Tax Year: {{taxYear}}</label>
      </div>
    </div>

    <div *ngIf="!navFrompg" class="row mb-2">
      <div class="col-sm-3 col-lg-2">
        <label for="selectedYear" class="h5">Select Tax Year</label>
        <ng-select id="selectedYear"
            [items]="selectableYears"
            [(ngModel)]="selectedYear"
            placeholder="Select tax year"
            (ngModelChange)="selectedYearChange()"
            [clearable]="false">
        </ng-select>
      </div>
      <div *ngIf="selectedYear > selectableYears[2]" class="col-12">
        <div class="invalid-feedback d-block"><b>Warning: For Projection Purposes Only!</b>   Taxes are based on a calendar year, and you cannot prepare your {{ selectedYear }} taxes until after that year is completed.</div>
    </div>
      <div class="col-12">
        <hr>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h5>Enter your information about your living situation during the year.
        </h5>
        <p>You must enter the full calendar year of days. Do not overlap days. Enter all days that you were present in
          Minnesota (even if for part of the day) as present in Minnesota. Enter days in other locations to start / end
          before or after those days. </p>
        <p>If you were not physically in Minnesota, you MUST indicate if you continued to pay rent, own, or otherwise
          occupy or maintain an abode during that time period. </p>
      </div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th colspan="10">Property Tax Refund Worksheet</th>
          </tr>
          <tr>
            <td class="col-md-2">Entry Date</td>
            <td class="col-md-2">Exit Date</td>
            <td class="col-md-2">U.S. State or Foreign Country
            </td>
            <td class="col-md-1">Was where you were staying an abode?</td>
            <td class="col-md-1">If you were not in Minnesota, did you still maintain a Minnesota residence that was an
              abode?
            </td>
            <td class="col-md-1">Earned U.S. income during this period</td>
            <td class="col-md-1">Days Stayed</td>
            <td class="col-md-1">Residential Status</td>
            <td class="col-md-1">Delete Record</td>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let dataentry of DateChecks; index as i; trackBy:trackIndex">
            <td class="col-md-2">
              <div class="input-group">

                <!--<input id="{{ 'entryDateInput_N' + dataentry.id }}" class="form-control" placeholder="MM/DD/YYYY"
                  name="entryn" ngbDatepicker #entryToggle="ngbDatepicker" [(ngModel)]="dataentry.entryraw"
                  (blur)="entryDateChangeNew(i)" attr.aria-label="Entry DateN {{id}}"
                  (dateSelect)="entryDateChangeNew(i)">  -->

                  <input id="{{ 'entryDateInput_N' + dataentry.id }}" class="form-control" placeholder="MM/DD/YYYY"
                  name="entryn" ngbDatepicker #entryToggle="ngbDatepicker" [(ngModel)]="entry2" [startdate]="entry2"
                  (blur)="entryDateChangeNew(i)" attr.aria-label="Entry DateN {{id}}"
                  (dateSelect)="entryDateChangeNew(i)">

                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="entryToggle.toggle()">
                    <fa-icon class="clickable" [icon]="['fa', 'calendar']"></fa-icon>
                  </button>
                </div>
              </div>
            </td>

            <td class="col-md-2">
              <div class="input-group">
                <input id="{{ 'exitDateInput_' + dataentry.id }}" class="form-control" placeholder="MM/DD/YYYY"
                  name="exit" ngbDatepicker #exitToggle="ngbDatepicker" [(ngModel)]="exit2" [startdate]="exit2"
                  (blur)="exitDateChangeNew(i)" attr.aria-label="Exit Date {{id}}" (dateSelect)="exitDateChangeNew(i)">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="exitToggle.toggle()">
                    <fa-icon class="clickable" [icon]="['fa', 'calendar']"></fa-icon>
                  </button>
                </div>
              </div>
            </td>

            <td class="col-md-2">
              <ng-select id="{{ 'country_' + dataentryId }}" [items]="selectableCountries"
                [(ngModel)]="dataentry.country" placeholder="Select a country"
                (ngModelChange)="onResidencyCheckChangedN(dataentry, i)" aria-label="Visa">
              </ng-select>
            </td>

            <td class="col-md-1">
              <ng-select id="{{ 'abode_' + dataentryId }}" [items]="selectableChoices"
                [(ngModel)]="dataentry.livedabode" placeholder="Was abode"
                (ngModelChange)="onResidencyCheckChangedN(dataentry, i)" aria-label="Visa"
                [disabled]="dataentry.country != 'Minnesota'">
              </ng-select>
            </td>

            <td class="col-md-1">
              <ng-select id="{{ 'maint_' + dataentryId }}" [items]="selectableChoices" [(ngModel)]="dataentry.keptabode"
                placeholder="maintained" (ngModelChange)="onResidencyCheckChangedN(dataentry, i)" aria-label="Visa"
                [disabled]="dataentry.country == 'Minnesota'|| dataentry.country == null">
              </ng-select>
            </td>

            <td class="col-md-1">
              <div class="btn-group" data-toggle="buttons">
                <label class="btn btn-dark" ngbButtonLabel>
                  <input type="checkbox" ngbButton [(ngModel)]="dataentry.usincome">
                </label>
              </div>
            </td>
            
            <td class="col-md-1"> {{ dataentry.dayslived }}</td>
            <td> {{ dataentry.status }}</td>

            <td class="col-md-1">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="removedataentry(i)">
                  X
                </button>
              </div>
            </td>

          </tr>

          <tr>
            <td class="col-md-1"> Total Recorded Days in tax year</td>
            <td> {{ daycount }}/365</td>
          </tr>

        </tbody>
      </table>
      <!-- 
      <br>
      Entr: {{DateChecks[0].entry}}
      <br>
      Entraw: {{DateChecks[0].entryraw}}
      <br>
      DCS: {{DateChecks}}
      <br>
      tres: {{targetres}}
      <br>
      fstat: {{failstate}}
      <br>
      dc: {{daycount}}
      <br>
      syear: {{selectedYear}}
      <br>
              -->

    </div>

    <div class="row">
      <div class="col-12">
        <a class="no-href clickable" (click)="addNewLocationCheck()">Add another Date Entry</a>
      </div>
      <div *ngIf="showNewResidencyCheckHelpText" class="col-12">
        <div class="invalid-feedback d-block">You have incomplete Date Entries! Finish adding information before
          adding another or submitting.</div>
      </div>
      <div *ngIf="showAbodeError" class="col-12">
        <div class="invalid-feedback d-block">You have missing abode status on a residency check</div>
      </div>
      <div *ngIf="showBoundingError" class="col-12">
        <div class="invalid-feedback d-block">You have an entry with a date outside the range of this year</div>
      </div>
      <div *ngIf="residencychecksHaveErrors" class="col-12">
        <div class="invalid-feedback d-block">Some of your Date Entries have errors. Please fix them before
          submitting.</div>
      </div>
      <div *ngIf="showOverlapError" class="col-12">
        <div class="invalid-feedback d-block">You have entered overlapping Date Entries. Please review and fix your
          entries before submitting.</div>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-4">
        <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmit()"
          [disabled]="residencychecksHaveErrors || showOverlapError">Check Status</button>
      </div>
      <div class="col-8">
        <button class="btn btn-danger float-right" (click)="clearResidencyChecks()">Clear Date Entries</button>
      </div>
    </div>
    <div class="row">
      <div *ngIf="showIncompleteError" class="col-12 my-2 alert alert-danger">
        <div>You must fill out location data for the entire year for this tool to work</div>
      </div>

      <div *ngIf="finalres == 1" class="col-12 my-2 alert alert-custom">
        <p>You are a Minnesota Nonresident for {{selectedYear}}. You federal tax residency is nonresident alien, you
          were physically present in the state of Minnesota for less than 183 days during 2021, and were not present in
          any other U.S. states during {{selectedYear}}. As a Minnesota Nonresident, you will only pay Minnesota
          income taxes on income from Minnesota sources. This means that if you only worked in Minnesota you will need
          to pay Minnesota income tax on ALL of that income. To prepare your Minnesota return, follow the instructions
          in the Basic Scenario suggested for you in the My Treaty Benefits tool. </p>
        <p>
      </div>
      <div *ngIf="finalres == 2" class="col-12 my-2 alert alert-custom">
        <p>You are a Minnesota Nonresident for {{selectedYear}}. Your federal tax residency is nonresident alien, and
          you were physically present in the state of Minnesota for less than 183 days during 2021. As a Minnesota
          nonresident, you will only pay Minnesota income taxes on income from Minnesota sources.</p>
        <p>
          <p>This means that if you only worked in Minnesota you will need to pay Minnesota income tax on ALL of your
            income. If this is your situation, follow the instructions in the Basic Scenario suggested for you in the My
            Treaty Benefits tool to prepare your federal and Minnesota income tax returns.</p>
          <p>
            <p>If you worked in Minnesota AND another state in the U.S., you will only need to pay Minnesota income
              taxes on
              the money you earned in Minnesota. If this is your situation, follow the instructions in the Basic
              Scenario
              suggested for you in the My Treaty Benefits tool to prepare your federal return. When you get to the state
              portion stop and then follow the “Working in Minnesota and Another State” special circumstances guide.</p>
            <p>
              <p>If you did not have any Minnesota source income, you do not need to file a Minnesota income tax return.
                If
                this is your situation, follow the instructions in the Basic Scenario suggested for you in the My Treaty
                Benefits tool to prepare your federal return.</p>
              <p>
                <p>
      </div>
      <div *ngIf="finalres == 3" class="col-12 my-2 alert alert-custom">
        <p>You are a Minnesota Part-Year Resident for {{selectedYear}}. You federal tax residency is nonresident
          alien. You were present in the state of Minnesota for at least 183 days, but not present in any other U.S.
          states during {{selectedYear}}. You (and your spouse, if married) did rent, own, maintain, OR occupy an
          abode (cooking, bathing, sleeping facilities) for some part of {{selectedYear}}. You are a Minnesota
          resident for the portion of the year that you rented, owned, occupied, or maintained your abode.</p>
        <p>
          <p>As a Minnesota Part-Year Resident, you will only pay Minnesota income taxes on income from Minnesota
            sources. This means that if you only worked in Minnesota you will need to pay Minnesota income tax on ALL of
            that income. To prepare your Minnesota return, follow the instructions in the Basic Scenario suggested for
            you in the My Treaty Benefits tool. You may be eligible for the Minnesota Property Tax Refund. Please review
            this special circumstances guide to determine if you qualify, and to prepare your return if so. </p>
          <p>
      </div>
      <div *ngIf="finalres == 4" class="col-12 my-2 alert alert-custom">
        <p>You are a Minnesota Part-Year Resident for {{selectedYear}}. You federal tax residency is nonresident
          alien. You were present in the state of Minnesota for at least 183 days, but not present in any other U.S.
          states during {{selectedYear}}. You (and your spouse, if married) did rent, own, maintain, OR occupy an
          abode (cooking, bathing, sleeping facilities) for some part of {{selectedYear}}. You are a Minnesota
          resident for the portion of the year that you rented, owned, occupied, or maintained your abode.</p>
        <p>
          <p>As a Minnesota Part-Year Resident, you will only pay Minnesota income taxes on income from Minnesota
            sources.
            This means that if you only worked in Minnesota you will need to pay Minnesota income tax on ALL of that
            income. To prepare your Minnesota return, follow the instructions in the Basic Scenario suggested for you in
            the My Treaty Benefits tool. You may be eligible for the Minnesota Property Tax Refund. Please review this
            special circumstances guide to determine if you qualify, and to prepare your return if so.</p>
          <p>
            <p>If you worked in Minnesota AND another state in the U.S., you will only need to pay Minnesota income
              taxes on the money you earned in Minnesota. If this is your situation, follow the instructions in the
              Basic Scenario suggested for you in the My Treaty Benefits tool to prepare your federal return. When you
              get to the state portion stop and then follow the “Working in Minnesota and Another State” special
              circumstances guide.</p>
            <p>
              <p>If you did not work in Minnesota and did not have any Minnesota source income, you do not need to file
                a Minnesota income tax return. If this is your situation, follow the instructions in the Basic Scenario
                suggested for you in the My Treaty Benefits tool to prepare your federal return.</p>
              <p>
                <p>
      </div>
      <div *ngIf="finalres == 5" class="col-12 my-2 alert alert-custom">
        <p>You are a Minnesota Full Year Resident for {{selectedYear}}. You federal tax residency is nonresident alien.
          You were present in Minnesota for at least 183 days during {{selectedYear}}. You or your spouse rented, owned,
          maintained, or occupied an abode for the entire year.</p>
        <p>
          <p>As a Minnesota Full Year Resident, you will owe Minnesota income tax on all of your U.S. income, even if
            you were working in another state to earn it. To prepare your Minnesota return, follow the instructions in
            the Basic Scenario suggested for you in the My Treaty Benefits tool. You may be eligible for the Minnesota
            Property Tax Refund. Please review this special circumstances guide to determine if you qualify, and to
            prepare your return if so.</p>
          <p>
            <p>You should NOT use the “Working in Minnesota and Another State Guide”. If you have income in another
              state, you can prepare that return after completing your federal and Minnesota returns per your basic
              scenario.</p>
              <p>You may be eligible for the Minnesota Property Tax Refund. Please review this special circumstances guide to determine if you qualify, and to prepare your return if so.</p>
            <p>
              <p>
      </div>
      <div *ngIf="finalres==2 || finalres==4 || finalres==5 " class=" col-12 my-2 alert alert-custom">
        <p>If you worked in another state, we have limited support that we can offer you.</p>
        <p>The following states do not have an income tax, and you will not need to prepare a state income tax return if
          you worked in: Alaska, Florida, Nevada, New Hampshire, South Dakota, Tennessee, Texas, Washington, and
          Wyoming.
          If you worked in one of those states you will not need to file a state income tax return for the income earned
          there.</p>
        <p>
          <p>If you worked in California you can use our “Working in California” special circumstances guide to prepare
            your
            return.</p>
          <p>
      </div>

    </div>
  </div>
  <div *ngIf="PrintMode" id="print-section">
    <table class="table table-bordered">
      <thead>
        <tr>
          <td class="col-md-1">Entry Date</td>
          <td class="col-md-1">Exit Date</td>
          <td class="col-md-1">U.S. State or Foreign Country
          </td>
          <td class="col-md-1">Was where you were staying an abode?</td>
          <td class="col-md-1">If you were not in Minnesota, did you still maintain a Minnesota residence that was an
            abode?
          </td>
          <td class="col-md-1">Earned U.S. income during this period</td>
          <td class="col-md-1">Days Stayed</td>
          <td class="col-md-1">Residential Status</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dataentry of DateChecks">
          <td class="col-md-1"> {{ dataentry.entryout }}</td>
          <td class="col-md-1"> {{ dataentry.exitout }}</td>
          <td class="col-md-1"> {{ dataentry.country }}</td>
          <td class="col-md-1"> {{ dataentry.livedabode }}</td>
          <td class="col-md-1"> {{ dataentry.keptabode }}</td>
          <td class="col-md-1"> {{ dataentry.usincome }}</td>
          <td class="col-md-1"> {{ dataentry.dayslived }}</td>
          <td> {{ dataentry.status }}</td>
        <tr>
        <tr>
          <td class="col-md-1"> Total Recorded Days in tax year</td>
          <td> {{ daycount }}/365</td>
        </tr>
    </table>
    <div class="row">
      <div *ngIf="showIncompleteError" class="col-12 my-2 alert alert-danger">
        <div>You must fill out location data for the entire year for this tool to work</div>
      </div>

      <div *ngIf="finalres == 1" class="col-12 my-2 alert alert-custom">
        <p>You are a Minnesota Nonresident for {{selectedYear}}. You federal tax residency is nonresident alien, you
          were physically present in the state of Minnesota for less than 183 days during 2021, and were not present in
          any other U.S. states during {{selectedYear}}. As a Minnesota Nonresident, you will only pay Minnesota
          income taxes on income from Minnesota sources. This means that if you only worked in Minnesota you will need
          to pay Minnesota income tax on ALL of that income. To prepare your Minnesota return, follow the instructions
          in the Basic Scenario suggested for you in the My Treaty Benefits tool. </p>
        <p>
      </div>
      <div *ngIf="finalres == 2" class="col-12 my-2 alert alert-custom">
        <p>You are a Minnesota Nonresident for {{selectedYear}}. Your federal tax residency is nonresident alien, and
          you were physically present in the state of Minnesota for less than 183 days during 2021. As a Minnesota
          nonresident, you will only pay Minnesota income taxes on income from Minnesota sources.</p>
        <p>
          <p>This means that if you only worked in Minnesota you will need to pay Minnesota income tax on ALL of your
            income. If this is your situation, follow the instructions in the Basic Scenario suggested for you in the My
            Treaty Benefits tool to prepare your federal and Minnesota income tax returns.</p>
          <p>
            <p>If you worked in Minnesota AND another state in the U.S., you will only need to pay Minnesota income
              taxes on
              the money you earned in Minnesota. If this is your situation, follow the instructions in the Basic
              Scenario
              suggested for you in the My Treaty Benefits tool to prepare your federal return. When you get to the state
              portion stop and then follow the “Working in Minnesota and Another State” special circumstances guide.</p>
            <p>
              <p>If you did not have any Minnesota source income, you do not need to file a Minnesota income tax return.
                If
                this is your situation, follow the instructions in the Basic Scenario suggested for you in the My Treaty
                Benefits tool to prepare your federal return.</p>
              <p>
                <p>
      </div>
      <div *ngIf="finalres == 3" class="col-12 my-2 alert alert-custom">
        <p>You are a Minnesota Part-Year Resident for {{selectedYear}}. You federal tax residency is nonresident
          alien. You were present in the state of Minnesota for at least 183 days, but not present in any other U.S.
          states during {{selectedYear}}. You (and your spouse, if married) did rent, own, maintain, OR occupy an
          abode (cooking, bathing, sleeping facilities) for some part of {{selectedYear}}. You are a Minnesota
          resident for the portion of the year that you rented, owned, occupied, or maintained your abode.</p>
        <p>
          <p>As a Minnesota Part-Year Resident, you will only pay Minnesota income taxes on income from Minnesota
            sources. This means that if you only worked in Minnesota you will need to pay Minnesota income tax on ALL of
            that income. To prepare your Minnesota return, follow the instructions in the Basic Scenario suggested for
            you in the My Treaty Benefits tool. You may be eligible for the Minnesota Property Tax Refund. Please review
            this special circumstances guide to determine if you qualify, and to prepare your return if so. </p>
          <p>
      </div>
      <div *ngIf="finalres == 4" class="col-12 my-2 alert alert-custom">
        <p>You are a Minnesota Part-Year Resident for {{selectedYear}}. You federal tax residency is nonresident
          alien. You were present in the state of Minnesota for at least 183 days, but not present in any other U.S.
          states during {{selectedYear}}. You (and your spouse, if married) did rent, own, maintain, OR occupy an
          abode (cooking, bathing, sleeping facilities) for some part of {{selectedYear}}. You are a Minnesota
          resident for the portion of the year that you rented, owned, occupied, or maintained your abode.</p>
        <p>
          <p>As a Minnesota Part-Year Resident, you will only pay Minnesota income taxes on income from Minnesota
            sources.
            This means that if you only worked in Minnesota you will need to pay Minnesota income tax on ALL of that
            income. To prepare your Minnesota return, follow the instructions in the Basic Scenario suggested for you in
            the My Treaty Benefits tool. You may be eligible for the Minnesota Property Tax Refund. Please review this
            special circumstances guide to determine if you qualify, and to prepare your return if so.</p>
          <p>
            <p>If you worked in Minnesota AND another state in the U.S., you will only need to pay Minnesota income
              taxes on the money you earned in Minnesota. If this is your situation, follow the instructions in the
              Basic Scenario suggested for you in the My Treaty Benefits tool to prepare your federal return. When you
              get to the state portion stop and then follow the “Working in Minnesota and Another State” special
              circumstances guide.</p>
            <p>
              <p>If you did not work in Minnesota and did not have any Minnesota source income, you do not need to file
                a Minnesota income tax return. If this is your situation, follow the instructions in the Basic Scenario
                suggested for you in the My Treaty Benefits tool to prepare your federal return.</p>
              <p>
                <p>
      </div>
      <div *ngIf="finalres == 5" class="col-12 my-2 alert alert-custom">
        <p>You are a Minnesota Full Year Resident for {{selectedYear}}. You federal tax residency is nonresident alien.
          You were present in Minnesota for at least 183 days during {{selectedYear}}. You or your spouse rented, owned,
          maintained, or occupied an abode for the entire year.</p>
        <p>
          <p>As a Minnesota Full Year Resident, you will owe Minnesota income tax on all of your U.S. income, even if
            you were working in another state to earn it. To prepare your Minnesota return, follow the instructions in
            the Basic Scenario suggested for you in the My Treaty Benefits tool. You may be eligible for the Minnesota
            Property Tax Refund. Please review this special circumstances guide to determine if you qualify, and to
            prepare your return if so.</p>
          <p>
            <p>You should NOT use the “Working in Minnesota and Another State Guide”. If you have income in another
              state, you can prepare that return after completing your federal and Minnesota returns per your basic
              scenario.</p>
              <p>You may be eligible for the Minnesota Property Tax Refund. Please review this special circumstances guide to determine if you qualify, and to prepare your return if so.</p>
              <p>
              <p>
      </div>
      <div *ngIf="finalres==2 || finalres==4 || finalres==5 " class=" col-12 my-2 alert alert-custom">
        <p>If you worked in another state, we have limited support that we can offer you.</p>
        <p>The following states do not have an income tax, and you will not need to prepare a state income tax return if
          you worked in: Alaska, Florida, Nevada, New Hampshire, South Dakota, Tennessee, Texas, Washington, and
          Wyoming.
          If you worked in one of those states you will not need to file a state income tax return for the income earned
          there.</p>
        <p>
          <p>If you worked in California you can use our “Working in California” special circumstances guide to prepare
            your
            return.</p>
          <p>
      </div>

    </div>

  </div>
  <button class="btn btn-custom-blue" (click)="togglePrintMode()" [disabled]="finalres==0 || finalres==null">
    Toggle Print Mode
  </button>
  <button class="btn btn-custom-blue" ngxPrint printSectionId="print-section" [useExistingCss]="true" [disabled]="finalres==0 || finalres==null">
    Print Results
  </button>

  <div *ngIf="showUnexpectedErrorMessage" class="col-12 my-2 alert alert-danger">
    <div>An unexpected error has occurred while determining your residency status. We apologize for any inconvenience.
      If you would still like to determine your residency status, please send us screenshots of your input to <a
        href="mailto:taxassistance@nrvtap.com">taxassistance@nrvtap.com</a> and we will assist in any way we can.
    </div>
  </div>


  <div *ngIf="navFrompg && showNextPageContinueButton">
    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button class="btn btn-custom mb-sm-0 mb-2 float-right" (click)="onSubmitGoToNextPage()">Continue</button>
        </div>
    </div>
  </div>


  <div *ngIf="navFrompg">
    <div class="row">
        <div class="col-12">
            <p>&nbsp;</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
        <button class="btn btn-custom mb-sm-0 mb-2" (click)="onSubmitBack()">Back</button>  
        </div>
    </div>
  </div>


</div>
