import { Injectable } from '@angular/core';

export interface guidePDF {
  questionnaireQandA: any;
}

export enum PGFilenames {
  Intro01 =   "Intro01_2023_02_20.pdf",
  Intro02 =   "Intro02_2023_02_16.pdf",
  Intro03 =   "Intro03_2023_02_03.pdf",
  Intro04a = "Intro04a_2023_02_03.pdf",
  Intro04b = "Intro04b_2023_03_20.pdf",
  Intro04c = "Intro04c_2023_03_20.pdf",
  Intro04d = "Intro04d_2023_02_03.pdf",
  Intro05 =   "Intro05_2023_02_03.pdf",
  Intro06 =   "Intro06_2023_02_03.pdf",
  Intro07 =   "Intro07_2023_02_20.pdf",
  Intro08 =   "Intro08_2023_02_15.pdf",
  Intro09 =   "Intro09_2023_02_16.pdf",
  
  EndIntro01 = "EndIntro01_2023_02_03.pdf",
  
  Depen01 =  "Depen01_2023_02_15.pdf",
  Depen02 =  "Depen02_2023_02_15.pdf",
  Depen03 =  "Depen03_2023_02_15.pdf",
  
  PersInfo01 = "PersInfo01_2023_03_31.pdf",
  PersInfo03 = "PersInfo03_2023_02_19.pdf",
  PersInfo04 = "PersInfo04_2023_02_03.pdf",
  PersInfo05 = "PersInfo05_2023_02_03.pdf",
  PersInfo06 = "PersInfo06_2023_02_03.pdf",
  PersInfo07 = "PersInfo07_2023_02_19.pdf",
  PersInfo08 = "PersInfo08_2023_02_03.pdf",
  PersInfo10 = "PersInfo10_2023_02_03.pdf",
  PersInfo11 = "PersInfo11_2023_02_03.pdf",
  PersInfo12 = "PersInfo12_2023_02_03.pdf",
  
  Spouse01 = "Spouse01_2023_02_03.pdf",
  Spouse02 = "Spouse02_2023_02_03.pdf",
  Spouse03 = "Spouse03_2023_02_03.pdf",
  
  StdDed01 = "Stdded01_2023_02_03.pdf",
  
  Wage01 = "Wage01_2023_02_16.pdf",
  Wage02 = "Wage02_2023_02_16.pdf",
  Wage03 = "Wage03_2023_03_29.pdf",
  Wage05 = "Wage05_2023_02_16.pdf",
  Wage06 = "Wage06_2023_02_16.pdf",
  Wage07 = "Wage07_2023_02_16.pdf",
  Wage08 = "Wage08_2023_02_16.pdf",
  Wage09 = "Wage09_2023_02_16.pdf",
  Wage10 = "Wage10_2023_02_16.pdf",
  
  SchFell01 =   "SchFell01_2023_02_11.pdf",
  SchFell02 =   "SchFell02_2023_03_29.pdf",
  SchFell03 =   "SchFell03_2023_03_29.pdf",
  SchFell04 =   "SchFell04_2023_03_29.pdf",
  SchFell04a = "SchFell04a_2023_03_29.pdf",
  SchFell05 =   "SchFell05_2023_03_29.pdf",
  SchFell06 =   "SchFell06_2023_02_22.pdf",
  SchFell07a = "SchFell07a_2023_03_29.pdf",
  SchFell08 =   "SchFell08_2023_03_29.pdf",
  SchFell09 =   "SchFell09_2023_03_29.pdf",
  SchFell10a = "SchFell10a_2023_03_29.pdf",
  
  StateRef01 = "StateRef01_2023_02_13.pdf",
  StateRef02 = "StateRef02_2023_02_13.pdf",

  Interest01 = "Interest01_2023_02_13.pdf",
  Interest02 = "Interest02_2023_02_13.pdf",
  Interest03 = "Interest03_2023_02_13.pdf",
  
  //Dividend01 = Treaty Benefits output table
  Dividend01 =  "Dividend01_2023_03_22.pdf",
  Dividend01b = "Dividend01b_2023_03_29.pdf",
  Dividend02 =  "Dividend02_2023_03_22.pdf",
  Dividend03 =  "Dividend03_2023_03_22.pdf",
  Dividend04 =  "Dividend04_2023_03_22.pdf",
  Dividend04a = "Dividend04a_2023_03_22.pdf",
  Dividend05 =  "Dividend05_2023_03_22.pdf",
  
  //CapGain01 = Treaty Benefits output table
  CapGain02 = "CapGain02_2023_02_13.pdf",
  CapGain03 = "CapGain03_2023_02_13.pdf",
  
  IRA401k01 = "IRA401k01_2023_02_13.pdf",
  
  OtherInc01 = "OtherInc01_2023_02_13.pdf",
  
  OOSIncome01 = "OOSIncome01_2023_02_13.pdf",
  
  CharDed01 = "CharDed01_2023_02_20.pdf",
  CharDed02 = "CharDed02_2023_02_13.pdf",
  
  StuLoan01 = "Stuloan01_2023_02_19.pdf",
  StuLoan02 = "Stuloan02_2023_02_13.pdf",

  State01 = "State01_2023_02_19.pdf",

  PA01 = "PA01_2023_03_24.pdf",
  NC01 = "NC01_2023_02_15.pdf",
  NC02 = "NC02_2023_02_15.pdf",
  NC02a = "NC02a_2023_03_08.pdf",
  CA01 = "CA01_2023_02_25.pdf",
  CA02a = "CA02a_2023_03_08.pdf",
  
  OtherState01 = "OtherState01_2023_02_15.pdf",
  OtherState02 = "OtherState02_2023_02_15.pdf",
  OtherState03 = "OtherState03_2023_02_15.pdf",
  
  AddlStTax01 = "AddlStTax01_2023_02_13.pdf",
  AddlStTax02 = "AddlStTax02_2023_02_04.pdf",
  
  MN01 = "MN01_2023_02_15.pdf",
  MN02 = "MN02_2023_02_15.pdf",
  MN02a = "MN02a_2023_03_08.pdf",
  MN03 = "MN03_2023_03_01.pdf",
  MN04 = "MN04_2023_02_15.pdf",
  MN05 = "MN05_2023_02_15.pdf",
  MN06 = "MN06_2023_02_15.pdf",
  MN07 = "MN07_2023_02_15.pdf",
  MN08 = "MN08_2023_02_19.pdf",
  
  Filing01 = "Filing01_2023_02_16.pdf",
  Filing02 = "Filing02_2023_02_22.pdf",

  Only884301 = "Only884301_2023_02_15.pdf",
  Only884302 = "Only884302_2023_02_15.pdf"
}
//TEST
@Injectable({
  providedIn: 'root'
})

export class PGnr08Service {

  constructor() { }

  pdfDocuments(): guidePDF {

    const statesList = [
      'Alabama',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming'
      ]
    

    let questionnaireQandA = [];

    questionnaireQandA.push('CITIZENSHIP & COUNTRY OF TAX RESIDENCY');
    questionnaireQandA.push('Are you a U.S. citizen?: ' + sessionStorage.USCitizen);
    if (sessionStorage.citizenCountry2 == 'null') {sessionStorage.citizenCountry2 = ' '}
    if (sessionStorage.citizenCountry3 == 'null') {sessionStorage.citizenCountry3 = ' '}
    questionnaireQandA.push('Citizenship country/countries: ' + sessionStorage.citizenCountry1 + ' ' + ' ' + ' ' + sessionStorage.citizenCountry2 + ' ' + ' ' + ' ' + sessionStorage.citizenCountry3);
    questionnaireQandA.push('Tax resident country: ' + sessionStorage.taxResidentCountry);
    questionnaireQandA.push('')

    questionnaireQandA.push('U.S. VISITS INFORMATION');
    for(var i = 1; i < Number(sessionStorage.visitCount) + 1; i++) {
      questionnaireQandA.push('Visit ' + i + ': ' + 
        'Visa: ' + sessionStorage['visitVisa' + i] + ', ' +
        'Entry: ' + sessionStorage['visitEntry' + i] + ', ' +
        'Exit: ' + sessionStorage['visitExit' + i])
    }
    questionnaireQandA.push('Calculated federal tax residency: ' + sessionStorage.nonResidentStatus);
    questionnaireQandA.push('')

    questionnaireQandA.push('SPOUSE')   
    questionnaireQandA.push('Are you married?: ' + sessionStorage.married);
    if (sessionStorage.married == 'Yes') {
      questionnaireQandA.push('Is your spouse a U.S. citizen?: ' + sessionStorage.spouseCitizen);
      questionnaireQandA.push('Does your spouse have a SSN or ITIN?: ' + sessionStorage.spouseSSNITIN);
      if (sessionStorage.spouseCitizen == 'Yes') {
        questionnaireQandA.push('How do you want to file?: ' + sessionStorage.spouseFilingType);
      }
      else {
        //questionnaireQandA.push('Does your spouse have a SSN or ITIN?: ' + sessionStorage.spouseSSNITIN);
        questionnaireQandA.push("What is your spouse's federal tax residency?: " + sessionStorage.spouseTaxResidency);
        if (sessionStorage.spouseTaxResidency == 'Resident' || sessionStorage.spouseTaxResidency == 'Dual Status') {
          questionnaireQandA.push('How do you want to file?: ' + sessionStorage.spouseFilingType);
        }
        else {
          questionnaireQandA.push('Was your spouse present on an F/J/M/Q visa?: ' + sessionStorage.spousePresentFJMQVisa);
        }
      }
    }
    questionnaireQandA.push('')

    if (sessionStorage.children != '') {      
        questionnaireQandA.push('CHILDREN')
        questionnaireQandA.push('Do you have children?: ' + sessionStorage.children);
        if (sessionStorage.children == 'Yes') {
          questionnaireQandA.push('Child born in the U.S.?: ' + sessionStorage.childBornUS);
          questionnaireQandA.push('Child present in U.S. on a -2 visa?: ' + sessionStorage.childPresentUS);
          questionnaireQandA.push('Child not in U.S. during ' + sessionStorage.taxYear + '?: ' + sessionStorage.childNotPresent);
        }
        questionnaireQandA.push('')
    }

    questionnaireQandA.push('WAGES & INCOME')
    if (sessionStorage.SSNITIN != 'null') { questionnaireQandA.push('Do you have a SSN or ITIN?: ' + sessionStorage.SSNITIN)}
    if (sessionStorage.incomeW2 == 'Yes') {questionnaireQandA.push('W2: ' + sessionStorage.incomeW2)}
    if (sessionStorage.income1042S16 == 'Yes') {questionnaireQandA.push('1042-S Box 1 Income Code 16: ' + sessionStorage.income1042S16)}
    if (sessionStorage.income1042S18 == 'Yes') {questionnaireQandA.push('1042-S Box 1 Income Code 18: ' + sessionStorage.income1042S18 )}
    if (sessionStorage.income1042S19 == 'Yes') {questionnaireQandA.push('1042-S Box 1 Income Code 19: ' + sessionStorage.income1042S19 )}
    if (sessionStorage.income1042S20 == 'Yes') {questionnaireQandA.push('1042-S Box 1 Income Code 20: ' + sessionStorage.income1042S20 )}
    if (sessionStorage.incomeScholarship == 'Yes') {questionnaireQandA.push('Scholarship or fellowship: ' + sessionStorage.incomeScholarship )}
    if (sessionStorage.income1099INT == 'Yes') {questionnaireQandA.push('1099-INT: ' + sessionStorage.income1099INT )}
    if (sessionStorage.income1099DIV == 'Yes') {questionnaireQandA.push('1099-DIV: ' + sessionStorage.income1099DIV )}
    if (sessionStorage.income1099B == 'Yes') {questionnaireQandA.push('1099-B: ' + sessionStorage.income1099B )}
    //if (sessionStorage.income1099G == 'Yes') {questionnaireQandA.push('1099-G: ' + sessionStorage.income1099G )}
    if (sessionStorage.income1099R == 'Yes') {questionnaireQandA.push('1099-R: ' + sessionStorage.income1099R )}
    if (sessionStorage.income1099Misc == 'Yes') {questionnaireQandA.push('1099-Misc: ' + sessionStorage.income1099Misc )}
    if (sessionStorage.income1099NEC == 'Yes') {questionnaireQandA.push('1099-NEC: ' + sessionStorage.income1099NEC )}
    if (sessionStorage.income1042SInterest == 'Yes') {questionnaireQandA.push('1042-S Interest: ' + sessionStorage.income1042SInterest )}
    if (sessionStorage.income1042SDividends == 'Yes') {questionnaireQandA.push('1042-S Dividends: ' + sessionStorage.income1042SDividends )}
    if (sessionStorage.incomeOther == 'Yes') {questionnaireQandA.push('Other: ' + sessionStorage.incomeOther )}
    if (sessionStorage.incomeNone == 'Yes') {questionnaireQandA.push('None: ' + sessionStorage.incomeNone  )}
    if (sessionStorage.incomeFedTaxWithheld != '') {questionnaireQandA.push('Did you have federal tax withheld?: ' + sessionStorage.incomeFedTaxWithheld)}
    if (sessionStorage.incomeINTPayor != '') {questionnaireQandA.push('Who did you receive interest from?: ' + sessionStorage.incomeINTPayor)}

    questionnaireQandA.push('')

    if (sessionStorage.TB == 'Yes') {
      questionnaireQandA.push('TREATY BENEFITS')
      const TBEntriesText = JSON.parse(sessionStorage.getItem('TB_Entries'))
      for(var i = 0; i < TBEntriesText.length; i = i + 4) {
        questionnaireQandA.push('Income Entry ' + (TBEntriesText[i] + 1) + ': ' + 
          'Type: ' + TBEntriesText[i + 1] + ', ' +
          'Income Code: ' + TBEntriesText[i + 2] + ', ' +
          'Income: ' + TBEntriesText[i + 3])
      }
      questionnaireQandA.push('')
    }

    questionnaireQandA.push('PAYMENTS & REFUNDS')  
    questionnaireQandA.push('Did you contribute to U.S. charity during ' + String(sessionStorage.taxYear - 1) + '?: ' + sessionStorage.charityContrib)
    questionnaireQandA.push('Did you receive a form 1098-E?: ' + sessionStorage.sLoan1098E)
    questionnaireQandA.push('Did you file an income tax return for ' + String(sessionStorage.taxYear - 1) + '?: ' + sessionStorage.filePrevTaxYear)
    if (sessionStorage.filePrevTaxYear == 'Yes') {
      questionnaireQandA.push('Did you receive a refund from a state income tax return?: ' + sessionStorage.stateRefund)
      if (sessionStorage.stateRefund == 'No') {
        questionnaireQandA.push('Did you pay additional state income tax?: ' + sessionStorage.payAddnlStateIncTax)
      }
    }
    questionnaireQandA.push('')

    questionnaireQandA.push('STATES & INCOME')
    questionnaireQandA.push('States lived in during ' + String(sessionStorage.taxYear) + ' and did you receive income while living in that state:')
    questionnaireQandA.push(sessionStorage.stateLived1 + ", " + sessionStorage.stateIncome1)
    if (sessionStorage.stateLived2 != '') {
      questionnaireQandA.push(sessionStorage.stateLived2 + ", " + sessionStorage.stateIncome2)
    }
    if (sessionStorage.stateLived3 != '') {
      questionnaireQandA.push(sessionStorage.stateLived3 + ", " + sessionStorage.stateIncome3)
    }
    questionnaireQandA.push('')

    if (sessionStorage.stateLived1 == 'Minnesota' || sessionStorage.stateLived2 == 'Minnesota' || sessionStorage.stateLived3 == 'Minnesota') {
      questionnaireQandA.push('PROPERTY TAXES');
      questionnaireQandA.push('Did you receive a certificate of rent paid?: ' + sessionStorage.certificateRentPaid);
      questionnaireQandA.push('Did you pay property tax in ' + String(sessionStorage.taxYear) + '?: ' + sessionStorage.payPropTax);
      questionnaireQandA.push('Did you receive a frontline worker payment?: ' + sessionStorage.frontlineWorker);
    }


    return {
      questionnaireQandA
    }
  }
}
