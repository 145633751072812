import { Injectable } from '@angular/core';

//import * as moment from 'moment';
import * as _ from 'lodash';

import { VisaType } from './docs/Visa';
import { ctbdata } from './docs/TBData';
import { Doc } from './docs/Docs';

export enum NonResidentStatusText {
    NonResident = 'Nonresident Alien',
    FullYearResident = 'Resident Alien',
    DualStatusAlien = 'Dual-Status Alien'
}
export enum Residencies {
    ResA = 'Resident Alien',
    NonResA = 'Nonresident Alien',
}

export const selectableVisaCodes=[
    'F-1 Student',
    'J-1 Student',
    'J-1 Non-Student',
    'F-2 Student',
    'J-2 Student',
    'J-2 Non-Student',
    'M-1 Student',
    'M-2 Student',
    'Q-1 Student',
    'Q-2 Student',
    'Non-exempt visa (B1/B2, TN, H1-b, etc.)',
    'Other Exempt visa (A, G, NATO, etc.)'
]

export const type_16_20_div_cap=[
    'F-1 Student',
    'J-1 Student',
    'M-1 Student',
    'Q-1 Student',
]
export const type_16_19_div_cap=[
    'J-1 Non-Student',
]

export const type_div_cap=[
    'F-2 Student',
    'J-2 Student',
    'J-2 Non-Student',
    'Q-2 Student',
    'Q-2 Non-Student',
    'Non-exempt visa (B1/B2, TN, H1-b, etc.)',
    'Other Exempt visa (A, G, NATO, etc.)'
]


export const TB_WagesSVisaCodes=[
    'F-1 Student',
    'J-1 Student',
    'M-1 Student',
    'Q-1 Student',
]

export const TB_WagesNSVisaCodes=[
    'J-1 Non-Student',
]

export const TB_ScholarshipsSVisaCodes=[
    'F-1 Student',
    'J-1 Student',
    'M-1 Student',
    'Q-1 Student',
]

export const countryindexes=[
     ctbdata[1][0],
     ctbdata[2][0],
     ctbdata[3][0],
     ctbdata[4][0],
     ctbdata[5][0],
     ctbdata[6][0],
     ctbdata[7][0],
     ctbdata[8][0],
     ctbdata[9][0],
    ctbdata[10][0],
    ctbdata[11][0],
    ctbdata[12][0],
    ctbdata[13][0],
    ctbdata[14][0],
    ctbdata[15][0],
    ctbdata[16][0],
    ctbdata[17][0],
    ctbdata[18][0],
    ctbdata[19][0],
    ctbdata[20][0],
    ctbdata[21][0],
    ctbdata[22][0],
    ctbdata[23][0],
    ctbdata[24][0],
    ctbdata[25][0],
    ctbdata[26][0],
    ctbdata[27][0],
    ctbdata[28][0],
    ctbdata[29][0],
    ctbdata[30][0],
    ctbdata[31][0],
    ctbdata[32][0],
    ctbdata[33][0],
    ctbdata[34][0],
    ctbdata[35][0],
    ctbdata[36][0],
    ctbdata[37][0],
    ctbdata[38][0],
    ctbdata[39][0],
    ctbdata[40][0],
    ctbdata[41][0],
    ctbdata[42][0],
    ctbdata[43][0],
    ctbdata[44][0],
    ctbdata[45][0],
    ctbdata[46][0],
    ctbdata[47][0],
    ctbdata[48][0],
    ctbdata[49][0],
    ctbdata[50][0],
    ctbdata[51][0],
    ctbdata[52][0],
    ctbdata[53][0],
    ctbdata[54][0],
    ctbdata[55][0],
    ctbdata[56][0],
    ctbdata[57][0],
    ctbdata[58][0],
    ctbdata[59][0],
]
export const resStatuses=[
    'Resident Alien',
    'Nonresident Alien',
]

export const binChoices=[
    'Yes',
    'No',
]


export interface NonResidentStatus {
    status: NonResidentStatusText;
    daysPresentInTaxYear: number;
    daysPresentYearPriorToTaxYear: number;
    daysPresent2YearsPriorToTaxYear: number;
    daysExemptInTaxYear: number;
    residencyStartDate?: string;
    residencyEndDate?: string;
    exempt3Of6Count: number;
    exemptAsForJInTaxYear: boolean;
    presentAsTeacherTraineeYears: number[];
    allExemptYears: number[];
    dualStatusResidentText: boolean;
}


@Injectable({
    providedIn: 'root'
})

export class tbService {
    /**
     * Checks if there is any overlap in an array of Docs
     * @param docs - Document array
     */
}
    
    




