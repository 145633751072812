import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ToolsComponent } from './tools/tools.component';
import { aminraComponent } from './tools/aminra/aminra.component';
import { tbComponent } from './tools/treatybenefits/tb.component';
import { PGnr00Component } from './tools/pg-nr00/pg-nr00.component';
import { PGnr01Component } from './tools/pg-nr01/pg-nr01.component';
import { PGnr02Component } from './tools/pg-nr02/pg-nr02.component';
import { PGnr03Component } from './tools/pg-nr03/pg-nr03.component';
import { PGnr04Component } from './tools/pg-nr04/pg-nr04.component';
import { PGnr05Component } from './tools/pg-nr05/pg-nr05.component';
import { PGnr06Component } from './tools/pg-nr06/pg-nr06.component';
import { PGnr07Component } from './tools/pg-nr07/pg-nr07.component';
import { PGnr08Component } from './tools/pg-nr08/pg-nr08.component';
import { StateresidencyComponent } from './tools/stateresidency/stateresidency.component';

// This describes our sites routes and their associated components.
const routes: Routes = [
  {
    // Home page - children under this path are specific tools
    path : '',
    children: [
      { path: 'aminra', component: aminraComponent, data: {title: 'Am I A NRA'} },
      { path: 'treatybenefits', component: tbComponent, data: {title: 'Treaty Benefits'} },
      { path: 'pg-nr00', component: PGnr00Component, data: {title: 'Introduction'} },
      { path: 'pg-nr01', component: PGnr01Component, data: {title: 'Citizenship & Tax Residency'} },
      { path: 'pg-nr02', component: PGnr02Component, data: {title: 'Spouse'} },
      { path: 'pg-nr03', component: PGnr03Component, data: {title: 'Children'} },
      { path: 'pg-nr04', component: PGnr04Component, data: {title: 'U.S. Income'} },
      { path: 'pg-nr05', component: PGnr05Component, data: {title: 'Payments & Refunds'} },
      { path: 'pg-nr06', component: PGnr06Component, data: {title: 'U.S. States Income'} },
      { path: 'pg-nr07', component: PGnr07Component, data: {title: 'Minnesota'} },
      { path: 'pg-nr08', component: PGnr08Component, data: {title: 'Download Guide'} },
      { path: 'stateresidency', component: StateresidencyComponent},
      { path: '', component: ToolsComponent, pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollOffset: [0, 32],
    //useHash:true  //ADDED TO TRY TO FIX NAVIGATION ISSUE (from https://github.com/angular/angular/issues/13948#issuecomment-302727428)
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
