import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pg-nr00',
  styleUrls: ['./pg-nr00.component.scss',],
  templateUrl: './pg-nr00.component.html',
})
export class PGnr00Component implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute)
   { }

  ngOnInit(): void {

    sessionStorage.taxYear = new Date().getFullYear()-1 //number
    sessionStorage.navDir = 'na' //forward,backward,na
    sessionStorage.pg = 'pgTrue' //pgTrue, pgFalse, null
    
    //pg-nr01
    sessionStorage.USCitizen = '' //Yes,No
    sessionStorage.citizenCountry1 = '' //string
    sessionStorage.citizenCountry2 = '' //string
    sessionStorage.citizenCountry3 = '' //string
    sessionStorage.taxResidentCountry = '' //string
    
    //AMINRA
    sessionStorage.nonResidentStatus = '' //Resident Alien, Nonresident Alien, Dual-Status Alien
    sessionStorage.visitVisa1 = '' //,2,3 //visa type, e.g. J-1 Student
    sessionStorage.visitEntry1 = '' //,2,3 //date
    sessionStorage.visitExit1 = '' //,2,3 //date
    sessionStorage.visitCount = '' //number
    sessionStorage.exemptCount = '' //number
    sessionStorage.visaType = '' //F-1, J-1 Student, etc.)
    sessionStorage.FJMQVisaType = '' //Yes,No - Is the visa one of any of the F/J/Q/M Visa types
    sessionStorage.FJMQChange = '' //Yes,No
    sessionStorage.stateResidencyVisitEntry = '' //date – For future use with integrated state incomes
    sessionStorage.stateResidencyVisitExit = '' //date – For future use with integrated state incomes
    sessionStorage.stateResidencyVisitCount = '' //number – For future use with integrated state incomes
    sessionStorage.daysInUSTY = '' //number
    sessionStorage.daysInUSTY1 = '' //number
    sessionStorage.daysInUSTY2 = '' //number
    
    //pg-nr02
    sessionStorage.married = '' //Yes,No
    sessionStorage.spouseCitizen = '' //Yes,No
    sessionStorage.spouseTaxResidency = '' //Resident, Nonresident, Dual Status
    sessionStorage.spouseFilingType = '' //Separate,Joint
    sessionStorage.spousePresentFJMQVisa = '' //Yes,No
    sessionStorage.spouseSSNITIN = '' //Yes,No
    
    //pg-nr03
    sessionStorage.children = '' //Yes,No
    sessionStorage.childBornUS = '' //Yes,No
    sessionStorage.childPresentUS = '' //Yes,No
    sessionStorage.childNotPresent = '' //Yes,No
    
    //pg-nr04
    sessionStorage.incomeW2 = '' //Yes,No
    sessionStorage.income1042S16 = '' //Yes,No
    sessionStorage.income1042S18 = '' //Yes,No
    sessionStorage.income1042S19 = '' //Yes,No
    sessionStorage.income1042S20 = '' //Yes,No
    sessionStorage.incomeScholarship = '' //Yes,No
    sessionStorage.income1099INT = '' //Yes,No
    sessionStorage.income1099DIV = '' //Yes,No
    sessionStorage.income1099B = '' //Yes,No
    //sessionStorage.income1099G = '' //Yes,No
    sessionStorage.income1099R = '' //Yes,No
    sessionStorage.income1099Misc = '' //Yes,No
    sessionStorage.income1099NEC = '' //Yes,No
    sessionStorage.income1042SInterest = '' //Yes,No
    sessionStorage.income1042SDividends = '' //Yes,No
    sessionStorage.incomeOther = '' //Yes,No
    sessionStorage.incomeNone = '' //Yes,No
    sessionStorage.SSNITIN = '' //Yes,No
    sessionStorage.incomeFedTaxWithheld = '' //Yes,No
    sessionStorage.incomeINTPayor = '' //Bank, IRS or Other
    sessionStorage.TB_IncomeDIVTreatyRate = ''; //text
    sessionStorage.TB_IncomeCapGainTreatyRate = ''; //text
    sessionStorage.TB_Dividend = ''; //Yes,No
    sessionStorage.TB_CapGain = ''; //Yes.No


    //TREATY BENEFITS
    
    sessionStorage.TB = 'No' //Yes,No
    sessionStorage.navFromTB = '' //Yes,No
    sessionStorage.navFromPGnr04 = '' //Yes,No
    sessionStorage.TB_Entries = '' // textblock
    sessionStorage.TB_MatchStatusWages = '' //No
    sessionStorage.TB_MatchStatusWagesSec1 = ''; //textblock
    sessionStorage.TB_MatchStatusWagesSec2 = ''; //textblock
    sessionStorage.TB_MatchStatusWagesSec3 = ''; //textblock
    sessionStorage.TB_MatchStatusScholarship = '' //No
    sessionStorage.TB_MatchStatusScholarshipSec1 = ''; //textblock
    sessionStorage.TB_MatchStatusScholarshipSec2 = ''; //textblock
    sessionStorage.TB_MatchStatusScholarshipSec3 = ''; //textblock
    sessionStorage.TB_Wages = 'No'; //Yes,No - is treaty benefit for wages for the combo of taxResidentCountry and visaType
    sessionStorage.TB_Scholarship = 'No'; //Yes,No - is treaty benefit for scholarships / fellowships for the combo of taxResidentCountry and visaType
    sessionStorage.TB_Dividend = ''; //Yes,No
    sessionStorage.TB_CapGain = ''; //Yes.No
    sessionStorage.TB_CapGainTable = ''; //text
    sessionStorage.TB_CapGainText = ''; //text
    sessionStorage.TB_CapGainSpecialNotes = ''; //text
    sessionStorage.TB_DividendTable = ''; //text
    sessionStorage.TB_DividendText = ''; //text
    sessionStorage.TB_DividendSpecialNotes = ''; //text
    sessionStorage.TB_IncomeDIVTreatyRate = ''; //text
    sessionStorage.TB_IncomeCapGainTreatyRate = ''; //text

    //pg-nr05
    sessionStorage.charityContrib = '' //Yes,No
    sessionStorage.sLoan1098E = '' //Yes,No
    sessionStorage.filePrevTaxYear = '' //Yes,No
    sessionStorage.stateRefund = '' //Yes,No
    sessionStorage.payAddnlStateIncTax = '' //Yes,No
    
    //pg-nr06
    sessionStorage.stateLived1 = '' //string
    sessionStorage.stateLived2 = '' //string
    sessionStorage.stateLived3 = '' //string
    sessionStorage.stateIncome1 = '' //Yes,No
    sessionStorage.stateIncome2 = '' //Yes,No
    sessionStorage.stateIncome3 = '' //Yes,No
    
    //pg-nr07
    sessionStorage.certificateRentPaid = '' //Yes,No
    sessionStorage.frontlineWorker = '' //Yes,No
    sessionStorage.payPropTax = '' //Yes/No

  }

  onSubmitStart() {
    sessionStorage.navDir = 'forward'
    sessionStorage.prevPage = 'pg-nr00'
    this.router.navigate(['../pg-nr01'], {relativeTo: this.route});
  }

}
